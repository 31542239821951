@import "../../scss/common/fonts";
@import "../../scss/common/colors";

.storybook-button {
  font-family: $eduate-font-family;
  font-weight: 600;
  font-size: $eduate-default-font-size;
  border: 0;
  border-radius: 6px;
  margin: 10px 10px 0px 0px;
  color: white;
  padding: 5px;
  text-transform: capitalize;

  img {
    width: 25px;
    padding-right: 2px;
    filter: invert(0%);
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.5), 6px 5px 5px rgba(0, 0, 0, 0.5);
  }
}

.storybook-button--default {
  background-color: var(--level-8);

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.storybook-button--otp {
  background-color: map-get($amber, level_6);
  border-radius: 30px;
  padding: 8px;
  width: 100% !important;

  &:hover {
    background-color: map-get($amber, level_5);
  }

  &:focus {
    background-color: map-get($amber, level_8);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

//primary-color
.storybook-button--addnew,
.storybook-button--add-message,
.storybook-button--user-rights {
  background-color: var(--level-8);

  &:hover {
    background-color: var(--level-4) !important;
  }

  &:focus {
    background-color: var(--level-7) !important;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.storybook-button--user-rights {
  img {
    filter: invert(100%);
  }
}

//sky
.storybook-button--edit,
.storybook-button--update,
.storybook-button--update-continue,
.storybook-button--previous,
.storybook-button--send-message,
.storybook-button--skip {
  background-color: map-get($sky, level_6);

  &:hover {
    background-color: map-get($sky, level_5);
  }

  &:focus {
    background-color: map-get($sky, level_8);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

//red

.storybook-button--delete {
  background-color: map-get($red, level_7);

  &:hover {
    background-color: map-get($red, level_6);
  }

  &:focus {
    background-color: map-get($red, level_8);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.storybook-button--change-number {
  background-color: map-get($red, level_5);

  &:hover {
    background-color: map-get($red, level_3);
  }

  &:focus {
    background-color: map-get($red, level_7);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

//fuchsia
.storybook-button--view,
.storybook-button--selected,
.storybook-button--fetch {
  background-color: map-get($fuchsia, level_8);

  &:hover {
    background-color: map-get($fuchsia, level_5);
  }

  &:focus {
    background-color: map-get($fuchsia, level_10);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

//navy-blue
.storybook-button--clear {
  background-color: #053e7a;

  &:hover {
    background-color: #4f7eb0;
  }

  &:focus {
    background-color: #052e59;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

//violet
.storybook-button--print,
.storybook-button--re-order {
  background-color: map-get($violet, level_6);

  &:hover {
    background-color: map-get($violet, level_5);
  }

  &:focus {
    background-color: map-get($violet, level_10);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

//black
.storybook-button--export,
.storybook-button--excel,
.storybook-button--pdf,
.storybook-button--extend {
  background-color: rgba(0, 0, 0, 0.8);

  &:hover {
    background: rgba(0, 0, 0, 0.48);
  }

  &:focus {
    background: rgba(0, 0, 0, 0.92);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

//yellow

.storybook-button--back,
.storybook-button--cancel {
  background-color: map-get($yellow, level_6);

  &:hover {
    background-color: map-get($yellow, level_4);
  }

  &:focus {
    background-color: map-get($yellow, level_7);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.storybook-button--close {
  background-color: map-get($red, level_6);

  &:hover {
    background-color: map-get($red, level_5);
  }

  &:focus {
    background-color: map-get($red, level_7);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
//teal

.storybook-button--submit,
.storybook-button--finish,
.storybook-button--save,
.storybook-button--save-continue,
.storybook-button--pay-fee,
.storybook-button--select {
  background-color: map-get($teal, level_6);

  &:hover {
    background-color: map-get($teal, level_4);
  }

  &:focus {
    background-color: map-get($teal, level_8) !important;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.storybook-button--sign-in {
  background-color: map-get($Green, level_6);
  border-radius: 30px;
  padding: 8px;
  width: 100%;

  &:hover {
    background-color: map-get($Green, level_5);
  }

  &:focus {
    background-color: map-get($Green, level_8);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.storybook-button--active,
.storybook-button--proceed-to-pay {
  background-color: map-get($Green, level_6);

  &:hover {
    background-color: map-get($Green, level_5);
  }

  &:focus {
    background-color: map-get($Green, level_8);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.storybook-button--sign-out {
  background-color: map-get($red, level_6);
  border-radius: 20px;
  padding: 6px;
  width: 100%;

  &:hover {
    background-color: map-get($red, level_5);
  }

  &:focus {
    background-color: map-get($red, level_8);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.storybook-button--change-number {
  background-color: map-get($red, level_6);
  border-radius: 20px;
  padding: 6px;
  width: calc(100% - 5px);

  &:hover {
    background-color: map-get($red, level_5);
  }

  &:focus {
    background-color: map-get($red, level_8);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

//black
.storybook-button--export,
.storybook-button--excel,
.storybook-button--pdf,
.storybook-button--download-excel,
.storybook-button--extend {
  background-color: rgba(0, 0, 0, 0.8);

  &:hover {
    background: rgba(0, 0, 0, 0.48);
  }

  &:focus {
    background: rgba(0, 0, 0, 0.92);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.storybook-button--next,
.storybook-button--move-to {
  background-color: map-get($Green, level_8);

  &:hover {
    background-color: map-get($Green, level_6);
  }

  &:focus {
    background-color: map-get($Green, level_10) !important;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.storybook-button--message {
  background-color: map-get($cool-grey, level_5);

  &:hover {
    background-color: map-get($cool-grey, level_5);
  }

  &:focus {
    background-color: map-get($cool-grey, level_10);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  img {
    filter: invert(100%);
  }
}
