.bulk_pic_upload {
  @include mainFrame;

  &__frame {
    @include layout();
  }

  &__tableblock {
    height: calc(100% - 135px);
    border: 1px solid var(--border-color);
    margin-top: 10px;
    .td-center {
      text-align: center;
    }
  }
  &__file {
    display: flex;
    width: 350px;
    column-gap: 10px;
    align-items: center;
    input {
      display: none;
    }
    button {
   @include browseFiles();
   img{
    filter:invert(100%);
    width:30px;
   }
    }
  }
}
