.mobile-login__pay-fee {
    margin: 20px;
    height: calc(100% - 40px);
    &--frame{
        height: calc(100% - 40px);

    }
    &--back {
        a {
            color: map-get($cool-grey, level_6);
            font-size: 14px;
            font-family: $eduate-font-family;
            img {
                width: 20px;
                transform: rotate(180deg);
            }
        }
    }
    &--title {
        font-family: $eduate-font-family;
        font-size: 18px;
        text-align: center;

    }
    &--cards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 10px;
        height: 100px;
    }
    &--card {
        text-align: center;
        row-gap: 5px;
        background-color: var(--disabled-color);
        padding: 5px;
        img {
            width: 45px;
        }
        span {
            font-family: $eduate-font-family;
            font-size: 14x;
            color: var(--text-color);
            display: block;
        }
        b {
            font-size: 16px;
            font-family: $eduate-number-font;
        }
    }
    &--block {
        margin-top: 10px;
        height: calc(100% - 145px);
        overflow: auto;
        position: relative;
        &--textfield {
            width: 100% !important;
            @include textfieldInForms();
            margin: 10px 0px 10px 0px !important;
        }
        &--button {
            .storybook-button {
                width: 100% !important;
                display: flex;
                justify-content: center;
            }
        }
        &--acn {
         &--list{
            box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
            0px 4px 6px -1px rgba(0, 0, 0, 0.1);
            padding: 2px;
         }
            &--title {
                font-family: $eduate-font-family;
                padding-top: 10px;
            }
            &--grid {
                display: grid;
                grid-template-columns: 1fr 0.1fr 1fr 0.1fr;
                column-gap: 10px;
                &--divider {
                    height: 100%;
                    border-left: 1.8px dashed var(--border-color);
                    margin: 0 2px;
                }
                &--ledgerwise{
                    display: grid;
                    grid-template-columns:0.1fr 1fr 0.1fr;
                    column-gap: 5px;
                    &--list{
                        @extend .mobile-login__pay-fee--block--acn--grid--ledgerwise;
                        column-gap: 10px;
                    }
                }
            }
            ul {
                margin-bottom: 0px;
                li {
                    display: flex;
                    justify-content: space-between;
                    span {
                        font-size: 14px;
                        font-family: $eduate-font-family;
                    }
                    b {
                        font-size: 14px;
                        font-family: $eduate-number-font;
                    }
                }
            }
            &--total {
                background-color: map-get($red, level_5);
                height: 40px;
                margin-top: 5px;
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: white;
                font-size: 14px;
                padding: 0px 10px;
                font-weight: 700;
                span {
                    font-family: $eduate-font-family;
                }
                b {
                    font-family: $eduate-number-font;
                }
            }
            &--footer{
                background-color: var(--disabled-color);
                height: 60px;
                margin-top: 5px;
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content:center;
               flex-direction: column;
                color: var(--text-color);
                font-size: 14px;
                padding: 0px 10px;
                font-weight: 700;
                position: sticky;
                top: auto;
                bottom: 0px;
                overflow: hidden;
                z-index: 9999;
                width: 100%;
                span {
                    font-family: $eduate-font-family;
                }
                b {
                    font-family: $eduate-number-font;
                }
            }
        }
        
    }
    &--buttons {
        height: 40px;
        .storybook-button {
            padding: 5px;
            text-transform: capitalize;

            img {
                width: 15px;
                padding-right: 2px;
                filter: invert(0%);
            }

            &:hover {
                cursor: pointer;
            }

            &:focus {
                box-shadow:
                    2px 1px 5px rgba(0, 0, 0, 0.5),
                    6px 5px 5px rgba(0, 0, 0, 0.5);
            }
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
.mobile-login__pay-fee {

    .storybook-input {
        height: 40px;
    }
    .react-tel-input .form-control {
        height: 40px !important;
    }
}
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .mobile-login__pay-fee {
        &--cards {
            height: 80px;
        }
        &--card {
            img {
                width: 30px;
            }
            span {
                font-size: 10px;
            }
            b {
                font-size: 12px;
            }
        }
        &--block {
            &--acn {
                &--title {
                    h6 {
                        font-size: 14px;
                    }
                }
                ul {
                    margin-bottom: 0px;
                    li {
                        span {
                            font-size: 11px !important;
                        }
                        b {
                            font-size: 10px !important;
                        }
                    }
                }
            }
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .mobile-login__pay-fee {
        &--cards {
            height: 80px;
        }
        &--card {
            img {
                width: 30px;
            }
            span {
                font-size: 10px;
            }
            b {
                font-size: 12px;
            }
        }
        &--block {
            &--acn {
                &--title {
                    span {
                        font-size: 10px;
                    }
                    b {
                        font-size: 10px;
                    }
                }
                ul {
                    margin-bottom: 0px;
                    li {
                        span {
                            font-size: 11px !important;
                        }
                        b {
                            font-size: 10px !important;
                        }
                    }
                }
            }
        }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .mobile-login__pay-fee{
        .storybook-input {
            height: 45px !important;
        }

    }
 
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 959px) {
    .mobile-login__pay-fee{
    .storybook-input {
        height: 40px !important;
    }
}
}
