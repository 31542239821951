.online-transaction {
  height: calc(100% - 165px);
  margin: 0px 20px;
&__title{
  img{
    @include closeIconInModal();
  }
}
  &__details {
    column-gap: 20px;
    height: 120px;

    &--flex {
      display: flex;

      .MuiAutocomplete-root,
      .data-fetch-icon {
        margin-top: 10px !important;
      }
    }

    &--textfield {
      @include textfield();
      margin: 10px 0px 0px 0px !important;
    }

    &--image {
      height: 100%;
      margin-top: 10px;
      @include studentProfile();
    }
  }

  &__filters {
    border-top: 1.5px dashed var(--border-color);
    column-gap: 10px;
    margin-top: 15px;
    padding-top: 5px;

    &--textfield {
      @include textfieldInForms();
      width: fit-content !important;
      float: right;
    }
  }

  &__tableblock {
    height: calc(100% - 100px);
    margin-top: 10px;
  }

  &__table {
    @include table();

    tr:hover {
      .online-transaction__table--vno {
        @include tdContainsOnclick();
      }
    }

    &--success {
      color: map-get($colors, white) !important;
      font-weight: 700 !important;
      background-color: map-get($Green, level_5);
      width: calc(100% - 94%);
      font-size: 16px !important;
    }

    &--failure {
      @extend .online-transaction__table--success;
      background-color: map-get($red, level_5);
    }

    &--no-voucher {
      @extend .online-transaction__table--success;
      background-color: map-get($amber, level_5);
    }

    &--incomplete {
      @extend .online-transaction__table--success;
      background-color: map-get($grey, level_5);
    }

    &--vno {
      font-weight: 700 !important;
    }

    &--message {
      width: calc(100% - 90%);
    }

    &--slno {
      width: calc(100% - 96%);
      font-family: $eduate-number-font;
    }

    &--date {
      width: calc(100% - 96%);
    }

    &--number {
      width: calc(100% - 96%);
    }

    &--actions {
      width: calc(100% - 94%);

      button {
        @include viewButton();
      }
    }
  }

  &__total-count {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: $eduate-default-font-size;
    font-family: $eduate-font-family;
    margin: 10px 20px 0px 20px;

    b {
      padding: 5px;
      font-family: $eduate-number-font;
      background: var(--level-5);
      border-radius: 2px;
      color: map-get($colors, white);
    }
  }
}

.all-transaction {
  height: calc(100% - 35px);
  &__select {
    column-gap: 10px;
  }
  .online-transaction__tableblock {
    height: calc(100% - 90px);
  }
}
