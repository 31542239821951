.std-enable-login {
    @include mainFrame();
    &__frame{
        @include layout();
    }
&__options{
    column-gap: 10px;
    .MuiAutocomplete-root{
        margin-top: 10px;
    }
}
&__tableblock{
    margin-top: 10px;
    height: calc(100% - 135px);
}

}