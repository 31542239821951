@import "./colors";

html,
body,
.h-100,
.edu-chat,
#root {
  height: 100%;
  margin: 0;
  overflow: auto;
  background-color: var(--bg-color);
}

button,
select,
i,
input {
  border: none;
  outline: none;
  border-radius: 6px;
}

ul {
  padding: 0;
  list-style: none !important;
  margin-bottom: 0px !important;
}

a {
  text-decoration: none;
}
.MuiTabs-indicator {
  display: none !important;
}
.MuiAutocomplete-endAdornment {
  right: 10px !important;

  img {
    width: 18px;
  }
}

.main {
  height: 100%;
}

.label-grid {
  display: grid;
  grid-template-columns: 0.6fr 1fr;
}

// to restrict window resize
.desktop-error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

#search {
  &::placeholder {
    padding-left: 20px;
    background-image: url(../../images/Search.svg);
    background-repeat: no-repeat;
    background-size: 15px;
    color: var(--text-color);
  }
}

.row-green {
  background-color: green;
}

.select-all {
  display: flex;
  margin-left: 30px;
  border-bottom: 1px dashed var(--level-2);

  &--checkbox {
    width: 20px;
    height: 20px;
  }

  Label {
    color: var(--level-5) !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    margin: 2px 10px 0px 10px !important;
    font-family: $eduate-font-family;
  }

  &__checkboxes {
    position: relative;
    display: flex;
    border-bottom: 1px dashed var(--level-2);
    padding: 5px;

    input {
      width: 18px !important;
      height: 18px !important;
      margin-top: 0px !important;
    }

    label {
      font-size: 12px !important;
      margin: 0px 10px 0px 10px !important;
      color: var(--text-color);
      font-family: $eduate-font-family;
    }
  }

  .MuiFormControlLabel-label {
    color: var(--level-5) !important;
    font-weight: bold !important;
    font-size: 18px !important;
    margin: 2px 10px 0px 10px !important;
    font-family: $eduate-font-family;
  }

  .Mui-checked {
    color: var(--level-5) !important;
  }
}

// for mobile number

.react-tel-input {
  top: 10px;
  font-family: $eduate-number-font;
  height: 30px;
  .flag-dropdown {
    height: 30px !important;
  }

  .form-control {
    width: 100% !important;
    height: 30px !important;
    border: 1px solid var(--border-color) !important;
    box-shadow: 3px 0px 0px red;

    &:focus {
      border: 1px solid var(--level-3) !important;
    }
  }

  input {
    height: 30px;

    &:focus {
      box-shadow: none !important;
    }
  }

  .flag-dropdown {
    height: 30px;
    padding-right: 15px;
    font-family: $eduate-font-family;
    font-size: 14px;
    font-weight: 400;
  }
}

.nodata {
  font-size: 16px;
  font-family: $eduate-font-family;
  color: map-get($colors, red);
  text-align: center;
}

.data-fetch-icon {
  background-color: var(--level-5);
  padding: 4px;
  border-radius: 6px;
  margin-left: 10px;
  margin-top: 10px;
  height: 30px;

  &:hover {
    cursor: pointer;
  }
}

.loading-time {
  text-align: center;

  span {
    font-weight: 500;
    font-family: $eduate-font-family;
    font-size: $eduate-default-font-size;
  }

  h4 {
    color: map-get($Green, level_8);
    font-family: $eduate-font-family;
    font-weight: 500;
  }

  .error-heading {
    color: map-get($colors, red);
    font-family: $eduate-font-family;
    font-weight: 500;
  }

  &__btn {
    display: flex;
    justify-content: flex-end;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--border-color) !important;

  &:hover {
    border: 1px solid var(--level-2) !important;
  }
}

.MuiAccordionSummary-expandIconWrapper {
  img {
    filter: invert(100%);
  }
}

.MuiAutocomplete-listbox {
  font-size: 12px !important;
  font-family: $eduate-font-family;
}

.MuiAutocomplete-option {
  &:hover {
    background-color: var(--level-2) !important;
  }

  &:focus {
    background-color: var(--level-2) !important;
  }
}

.MuiFormLabel-root,
.MuiInputLabel-root {
  color: var(--text-color) !important;
  font-family: $eduate-font-family;
}
.font-blue {
  color: map-get($sky, level_6);
}
.font-red {
  color: map-get($red, level_6);
}
.font-green {
  color: map-get($Green, level_6);
}

.btn {
  display: flex;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}

.time-input {
  border: 1px solid var(--border-color);
  width: 65px;
  display: flex;
  height: 30px;
  color: black;
  font-family: $eduate-font-family;
  font-size: $eduate-default-font-size;
  align-items: center;
  input {
    height: 100%;
    width: 30px;
    caret-color: transparent;
    text-align: center;
    border-radius: 0;
  }
  input:focus {
    background-color: var(--level-2);
  }
}
