@import "../../scss/common/fonts";
@import "../../scss/common/colors";

.Label {
  font-family: $eduate-font-family;
  font-style: normal;
  font-weight: 400 !important;
  font-size: $eduate-default-font-size;
  color: var(--text-color);
  margin-top: 10px;
  padding: 5px;
}



.LabelBold {
  @extend .Label;
  font-weight: 600 !important;
}

.LabelPrimary {
  @extend .Label;
  color: var(--level-6);
  font-weight: 600;
}

.present-day {
  @extend .Label;
  margin-top: 5px !important;
  margin-left: 10px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  padding: 4px;
  background-color: var(--disabled-color);
}