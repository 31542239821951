.section-allocation{
    @include mainFrame();
    &__frame{
        @include layout();
    }
    &__tabs{
        @include muiTabs();
    }
    &__tabpanel{
        height: calc(100% - 75px);
     .swipable-tabs,   p{
            height: 100%;
        }
    }
    &__options{
        column-gap: 10px;
        margin-top: 5px;
        .MuiAutocomplete-root{
            margin-top: 10px;
        }
    }
    &__tableblock{
        height: calc(100% - 105px );
        margin-top: 10px;
    }
    &__table{
        @include table();
        &--slno{
            width: calc(100% - 92%);
            font-family: $eduate-number-font;
        }
        &--admno{
            width: calc(100% - 88%);
        }
        &--desc{
            width: calc(100% - 90%);
        }
    }
    &__move-to{
        height: 100%;
        padding: 0px 20px;
        &--tableblock{
            height: calc(100% - 155px);
        }
    }
}