:root,
:root.pink {
    --level-1: #fff5f7;
    --level-2: #fed7e2;
    --level-3: #fbb6ce;
    --level-4: #f687b3;
    --level-5: #ed64a6;
    --level-6: #d53f8c;
    --level-7: #b83280;
    --level-8: #97266d;
    --level-9: #702459;
    --level-10: #521b41;
    --bg-color: white;
    --text-color: black;
    --border-color: #e2e8f0;
    --modal-bg-color: rgb(255, 255, 255, 255);
    --disabled-color: #f3f4f6;
}

:root.amber {
    --level-1: #fffbeb;
    --level-2: #fef3c7;
    --level-3: #fde68a;
    --level-4: #fcd34d;
    --level-5: #fbbf24;
    --level-6: #f59e0b;
    --level-7: #d97706;
    --level-8: #b45309;
    --level-9: #92400e;
    --level-10: #78350f;
    --bg-color: white;
    --text-color: black;
    --border-color: #e2e8f0;
    --modal-bg-color: rgb(255, 255, 255, 255);
    --disabled-color: #f3f4f6;
}

:root.Green {
    --level-1: #f0fff4;
    --level-2: #c6f6d5;
    --level-3: #9ae6b4;
    --level-4: #68d391;
    --level-5: #48bb78;
    --level-6: #38a169;
    --level-7: #25855a;
    --level-8: #276749;
    --level-9: #22543d;
    --level-10: #1c4532;
    --bg-color: white;
    --text-color: black;
    --border-color: #e2e8f0;
    --modal-bg-color: rgb(255, 255, 255, 255);
    --disabled-color: #f3f4f6;
}

:root.fuchsia {
    --level-1: #fdf4ff;
    --level-2: #fae8ff;
    --level-3: #f5d0fe;
    --level-4: #f0abfc;
    --level-5: #e879f9;
    --level-6: #d946ef;
    --level-7: #c026d3;
    --level-8: #a21caf;
    --level-9: #86198f;
    --level-10: #701a75;
    --bg-color: white;
    --text-color: black;
    --border-color: #e2e8f0;
    --modal-bg-color: rgb(255, 255, 255, 255);
    --disabled-color: #f3f4f6;
}

:root.teal {
    --level-1: #e6fffa;
    --level-2: #b2f5ea;
    --level-3: #81e6d9;
    --level-4: #4fd1c5;
    --level-5: #38b2ac;
    --level-6: #319795;
    --level-7: #2c7a7b;
    --level-8: #285e61;
    --level-9: #234e52;
    --level-10: #1d4044;
    --bg-color: white;
    --text-color: black;
    --border-color: #e2e8f0;
    --modal-bg-color: rgb(255, 255, 255, 255);
    --disabled-color: #f3f4f6;
    --disabled-border: white;
}

:root.sky {
    --level-1: #f0f9ff;
    --level-2: #e0f2fe;
    --level-3: #bae6fd;
    --level-4: #7dd3fc;
    --level-5: #38bdf8;
    --level-6: #0ea5e9;
    --level-7: #0284c7;
    --level-8: #0369a1;
    --level-9: #075985;
    --level-10: #0c4a6e;
    --bg-color: white;
    --text-color: black;
    --border-color: #e2e8f0;
    --modal-bg-color: rgb(255, 255, 255, 255);
    --disabled-color: #f3f4f6;
}

.theme-image {
    width: 25px !important;
    filter: invert(0%);
}

.theme-option {
    margin-top: -10px !important;

    .MuiOutlinedInput-notchedOutline {
        border: none !important;
    }

    .MuiSvgIcon-root,
    .MuiSvgIconn-root {
        display: none !important;
    }

    .MuiSelect-select,
    .MuiInputBase-input,
    .MuiOutlinedInput-input {
        padding: 5px !important;
    }
}

.theme-menu {
    display: inline-flex !important;
}