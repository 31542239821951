.std-dashboard {
  height: calc(100% - 130px);

  &__frame {
    height: calc(100% - 50px);
    column-gap: 20px;
    margin: 10px 20px 0px 20px;
    display: flex;
  }

  &__title {
    display: flex;
    justify-content: space-between;

    img {
      width: 25px;
    }
  }

  &__ls {
    height: 100%;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    padding: 5px;
    width: 300px;

    &--ul {
      height: calc(100% - 10px);
      overflow: auto;

      li {
        display: flex;
        font-family: $eduate-font-family;
        font-size: 16px;
        color: var(--text-color);
        padding: 8px 4px;
        border-radius: 6px;

        &:hover {
          background: var(--level-1);
        }


      }

      .active {
        background-color: var(--level-1);
        color: map-get($colors, white);
      }
    }


  }

  &__md {
    height: 100%;
    //  @extend board__ls;
    width: calc(100% - 550px);

    .chat-channel__data-block {
      margin-top: 0px;
      height: 100% !important;
    }

    .feeds__data--list--content {
      width: 100%;
    }

    .feeds__data,
    .feeds__data--list {
      height: 100%;
    }
  }

  &__rs {
    width: 250px;

    &--top {
      height: 180px;
      border: 1px solid var(--border-color);
      border-radius: 6px;
      padding: 5px;
    }

    &--bottom {
      border: 1px solid var(--border-color);
      border-radius: 6px;
      padding: 5px;
      height: calc(100% - 190px);
      margin-top: 10px;
    }
  }

  &__announcements {
    height: 100%;

    &--title {
      display: flex;
      justify-content: space-between;

      img {
        width: 25px;
      }
    }

    &--block {
      height: calc(100% - 30px);
    }

    &--slideshow {
      overflow: hidden;
      width: 100%;
      height: calc(100% - 10px);
      margin-top: 10px;
    }

    &--slider {
      display: flex;
      column-gap: 2px;
      // transition: ease 2s;
      height: calc(100% - 25px);
      width: 100%;
    }

    &--slide {
      flex: 0 0 100%;
      height: 100%;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 5px 10px;
      width: 100%;

      b {
        font-family: $eduate-font-family;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      span {
        margin-top: 10px;
        font-size: 14px;
        font-family: $eduate-number-font;
      }
    }

    &--dots {
      text-align: center;
    }

    &--dot {
      display: inline-block;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      cursor: pointer;
      margin: 10px 5px 0px;
      background-color: #c4c4c4;
    }
  }

  &__std-details {
    height: calc(100% - 50px);

    &--profile {
      height: 60px !important;
      width: 60px !important;
      display: block;
      margin: 10px auto;

      &:hover {
        .std-dashboard__std-details--edit {
          display: block;
          width: 30px;
          height: 30px;
          margin-top: -30px;
          margin-left: auto;
          margin-right: 8px;
          color: black;
          z-index: 10;
          opacity: 1;
        }

        .std-dashboard__std-details--image {
          opacity: 0.5;
          z-index: 2;
        }
      }
    }

    &--profile-noedit {
      height: 60px !important;
      width: 60px !important;
      display: block;
      margin: 10px auto;
    }

    &--image {
      height: 100% !important;
      width: 100% !important;
      border-radius: 50% !important;
    }

    &--edit {
      display: none;
    }

    &--file {
      display: none;
    }

    &--data {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &--flex {
        display: flex;
        column-gap: 5px;
        padding: 2px;
        border-bottom: 1px dashed var(--border-color);
        font-size: 13px;
        font-family: $eduate-font-family;
      }
    }
  }
}

.std-dashboard__announcements--dot.active {
  background-color: var(--level-5);
  width: 30px;
  border-radius: 30px;
  height: 15px;
}



.std-dashboard__ls--ul::-webkit-scrollbar {
  @include scrollbar();
}


.std-dashboard__ls--ul::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}


.std-dashboard__ls--ul::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}