.login {
  height: 100%;
  display: flex;
  position: relative;

  background-image: url("../../../images/SandeshCharacter.svg");
  background-size: 93% auto;
  background-repeat: no-repeat;
  // animation: change-img-anim 10s infinite;
  z-index: 999 !important;
  overflow: hidden !important;

  &__left-block {
    height: 100%;
    width: 75%;
  }

  &__right-block {
    height: 100%;
    width: 35%;
    z-index: 2;

    &--container {
      margin: 20px;
      height: calc(100% - 40px);
      width: calc(100% - 40px);
      border-radius: 30px;
      background: rgba(113, 128, 150, 0.05);
      box-shadow: -61.733333587646484px 61.733333587646484px
          61.733333587646484px 0px rgba(255, 255, 255, 0.07) inset,
        61.733333587646484px -61.733333587646484px 61.733333587646484px 0px rgba(
            86,
            97,
            114,
            0.07
          ) inset;
      backdrop-filter: blur(12.5px);
      display: flex;
      justify-content: center;
      align-items: center;

      &--data {
        width: 100%;

        &--title {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 10px;

          h4 {
            font-weight: 700;
            font-size: 40px;
            font-family: $eduate-font-family;
            margin-top: 10px;
            color: $RED_COLOR;
          }
        }
      }

      b {
        font-weight: 700;
        font-size: 16px;
        display: flex;
        justify-content: center;
        font-family: $eduate-font-family;
        margin-top: 20px;
      }
    }
  }

  &__block {
    height: 310px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: -61.733333587646484px 61.733333587646484px 61.733333587646484px
        0px rgba(255, 255, 255, 0.07) inset,
      61.733333587646484px -61.733333587646484px 61.733333587646484px 0px rgba(
          86,
          97,
          114,
          0.07
        ) inset;
    backdrop-filter: blur(12.5px);
    margin: 20px;
    background: map-get($grey, level_8);

    .Label {
      color: white;
    }

    .MuiFormControl-root {
      background-color: var(--bg-color) !important;
      border-radius: 10px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 0px !important;
    }

    .MuiOutlinedInput-input {
      padding: 5.5px 14px !important;
    }

    .MuiSvgIcon-root {
      font-size: 20px !important;
    }

    &--tabs {
      @include muiTabsForLogin();
    }
  }
}

.loginfield {
  width: 100%;

  input {
    font-weight: 400 !important;
  }
}

.login__left-block--container {
  width: 100%;
  height: 100%;
  position: relative;

  &--image {
    width: 100%;
    height: 100%;
  }
}

// @keyframes change-img-anim {

//   0%,
//   50% {
//     background-image: url("../../../images/AdminCharacter.png");
//     z-index: 10;
//   }

//   51%,
//   100% {
//     background-image: url("../../../images/LoginImage.png");
//     z-index: 10;
//   }
// }
