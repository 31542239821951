.channel-members-list {
    height: 100%;
    width: 100%;

    .storybook-input {
        margin-top: 0px !important;
    }

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 30px;
        padding: 0px 5px;
    }

    &--students {
        height: 100%;
        width: 100%;

        &--list {
            height: calc(100% - 80px);
            width: 100%;
            overflow: auto;

            li {
                display: flex;
                justify-content: space-between;
                column-gap: 10px;
                align-items: center;
                width: 100%;
                column-gap: 5px;
                padding: 5px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                &:hover {
                    background-color: var(--level-2);
                    border-radius: 3px;

                    .channel-members-list--students--list--send {
                        display: flex;
                        width: 20px;
                    }
                }

                b {
                    font-family: $eduate-font-family;
                    font-size: $eduate-default-font-size;
                }

                img {
                    width: 25px;
                }
            }

            &--flex {
                display: flex;
                column-gap: 10px;
                justify-content: space-between;
                align-items: center;
                .MuiCheckbox-root {
                    padding: 0px !important;
                }
                b {
                    font-size: 14px;
                    font-family: $eduate-font-family;
                }
            }

            &--send {
                display: none;
            }
            &--ul {
                @extend .channel-members-list--students--list;
                height: calc(100% - 125px);
            }
            &--button {
                button {
                    width: 100% !important;
                }
            }
        }
    }
}

.channel-members-list--students--list::-webkit-scrollbar,
.channel-members-list--students--list--ul::-webkit-scrollbar {
    @include scrollbar();
}

.channel-members-list--students--list::-webkit-scrollbar-thumb,
.channel-members-list--students--list--ul::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}

.channel-members-list--students--list::-webkit-scrollbar-thumb:hover,
.channel-members-list--students--list--ul::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}
