.masterconfig {
    &__tree-view {
        height: calc(100% - 35px);
        overflow: auto;

        &--ol {
            border-left: 2px dashed var(--border-color) !important;
        }

        &--icons {
            width: 20px;
        }

        &--li {
            display: flex;
            align-items: center;
            column-gap: 5px;
            border-radius: 30px;
            padding: 5px;
            margin-left: -15px;
            font-family: $eduate-font-family;
            font-size: 14px;

            &:hover {
                background-color: var(--level-2);
                font-weight: 600;
            }

            &:focus {
                background-color: var(--level-4);
                color: white;
            }
        }
    }
}

.masterconfig__tree-view::-webkit-scrollbar {
    @include scrollbar();
}

.masterconfig__tree-view::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}

.masterconfig__tree-view::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}