.user-rights {
    @include mainFrame();

    .subtitle1 {
        margin-top: 10px !important;
    }

    &__frame {
        @include layout();

        &--data {
            height: calc(100% - 45px);
            margin-top: 10px;




        }

        &--tabs {
            @include muiTabs();
        }

        &--tab-panel {
            height: calc(100% - 55px);
            margin-top: 10px;

            .swipable-tabs,
            p {
                height: 100%;
            }
        }



    }

    &__add {
        &--title {
            img {
                @include closeIconInModal()
            }
        }
    }

    &__login-user {
        height: 100%;

        &--title {
            img {
                @include closeIconInModal();
            }
        }

        &--button {
            @include addButton();
        }

        &--tableblock {
            margin-top: 10px;
            height: calc(100% - 125px);
        }
        &--select{
            column-gap: 10px;
            .MuiAutocomplete-root{
                margin-top: 10px !important;
                
            }
        }
    }
    &__select{
      column-gap: 10px;
      &--textfield{
        @include textfield;
        width: fit-content !important;
        margin-left: 5px !important;
      }
    }
    &__detailed-view {
      height: 100%;
      &--block{
        height: calc(100% - 75px);
        overflow: auto;
      
      }
      &--file-image{
width:240px;
height:240px;
margin: 10px auto;
display: block;
      }
    }
}
.excel-loading {
    height: 450px;
    width: 400px;
    border: 1px solid var(--border-color);
  progress{
    width:100%;
  }
    &__top {
      height: 30%;
      background-color: var(--level-1);
      padding-top: 20px;
      img {
        width: 30px;
        height: 30px;
      }
      b {
        font-family: $eduate-font-family;
        color: var(--level-6);
        font-size: 25px;
      }
      &--flex {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &--text {
        font-size: 20px;
        color: map-get($cool-grey, level_6);
        text-align: center;
        font-family: $eduate-font-family;
      }
      &--total {
        text-align: center;
        font-family: $eduate-number-font;
        font-size: 20px;
      }
      &--details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: calc(100% - 30px);
      }
    }
    &__bottom {
      height: 70%;
      &--flex {
        display: flex;
        justify-content: space-between;
        height: 40px;
        &--f {
          display: flex;
          column-gap: 10px;
          align-items: center;
          img {
            width: 30px;
            height: 30px;
          }
          span {
            font-size: 16px;
            font-family: $eduate-number-font;
          }
          b {
            font-size: 18px;
            font-family: $eduate-font-family;
          }
        }
      }
      &--loading {
        width: 100%;
        height: calc(100% - 40px);
        img {
          height: 180px;
          width: 200px;
          display: block;
          margin: auto;
        }
      }
      &--details {
        height: calc(100% - 25px);
        padding: 40px 40px 30px 40px;
      }
    }
  }


 
.user-rights__detailed-view--block::-webkit-scrollbar {
    @include scrollbar();
}


.user-rights__detailed-view--block::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}


.user-rights__detailed-view--block::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}