.std-device-details{
    &__drawer {
        height: 100%;
        &--textfield{
            @include textfield();
            &--date{
                @include textfield();
                width: fit-content !important;

            }
        }
        &--title {
            img {
                @include closeIconInModal();
            }
        }
        &--details {
            column-gap: 10px;
            height: 140px;
            border-bottom: 1px dashed var(--border-color);
        }
        &--total {
            background-color: var(--level-1);
            border-radius: 6px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px;
            margin-top: 10px;
            span {
                font-size: 16px;
                font-family: $eduate-font-family;
            }
            &--t {
                display: flex;
                column-gap: 5px;
                align-items: center;
                b {
                    font-size: 14px;
                    font-family: $eduate-number-font;
                    padding: 5px 7px;
                    color: map-get($colors, white);
                    background-color: map-get($Green,  level_4);
                    border-radius: 2px;
                }
            }
        }
        &--ul {
            margin-top: 10px;
            margin-bottom: 0px;
            height: calc(100% - 295px);
            overflow: auto;
            li{
                display: flex;
                align-items: center;
                font-family: $eduate-font-family;
                column-gap: 5px;
                border: 1px solid var(--border-color);
                border-radius: 6px;
                padding: 10px;
                box-shadow: 6px 4px 4px rgba(0, 0, 0, 0.25);
                margin-top: 10px;

                img{
                    width: 25px;
                    height: 25px;
                }

                b{
                    font-size: 16px;
                    color: map-get($blue, level_7);
                }
                span{
                    font-size: 14px;
                    color: map-get($cool-grey, level_5);
                }
            }
        }
        &--image{
            @include studentProfile();
        }
    }

}

.std-device-details__drawer--ul::-webkit-scrollbar {
    @include scrollbar();
  }
  
  .std-device-details__drawer--ul::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
  }
  
  .std-device-details__drawer--ul::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
  }