.chat-channel-messages {
    height: 100%;
    overflow: auto;
    display: flex;
    padding: 5px 20px;

    ul {
        width: 100% !important;
        margin-bottom: 0px !important;
    }

    &--list {
        display: flex;
        gap: 10px;
        height: fit-content;
        width: fit-content;
        border-radius: 10px;
        background-color: var(--disabled-color);
        padding: 5px 10px;

        p {
            margin-bottom: 0px !important;
        }

        &--li {
            padding: 5px 200px 5px 5px;
        }

        Label {
            margin-top: 2px;
            padding: 0px;
            font-weight: 700 !important;
            margin-right: 10px;
        }

        &--sender {
            border-radius: 6px;
            display: flex;
            justify-content: flex-end !important;
            margin-right: 0% !important;
            width: fit-content;
            margin-left: auto !important;
            background-color: var(--level-1);
            padding: 5px 10px;
            border-radius: 6px;

            &--li {
                padding: 5px 5px 5px 200px;
            }

            &--left {
                width: 25%;
            }
        }
    }

    &--image {
        width: 35px;
        height: 35px;
        margin-top: 5px;
    }

    &--time {
        font-family: $eduate-number-font;
        font-size: 12px;
        font-weight: 700;
        text-align: right;
    }

    &--file-image {
        width: 320px;
        height: 240px;
    }
}