.inst-config {
    @include mainFrame();

    &__frame {
        @include layout();
    }

    &__menus {
        display: flex;
        column-gap: 20px;
        margin-bottom: 0px !important;
        margin-top: 10px;

        .active {
            background-color: var(--level-5);
            color: map-get($colors, white);
            font-weight: 700;
        }

        li {
            border: 1px solid var(--border-color);
            padding: 5px 8px;
            border-radius: 6px;
            font-family: $eduate-font-family;
            font-size: $eduate-default-font-size;
            color: map-get($grey, level_7);
        }
    }
}