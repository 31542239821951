.sidebar {
    width: 210px;
    border-right: 1px solid white;
    box-shadow: 6px 4px 4px rgba(0, 0, 0, 0.25);
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 0px 5px;

    li {
        margin-top: 5px;

        span,
        a {
            font-size: $eduate-default-font-size;
            color: black;
            box-shadow: none;
            display: inline-block;
            font-family: $eduate-font-family;
            padding: 2px 0px;
        }

        img {
            margin-right: 5px;
            width: 25px;
        }

        &:hover {
            background-color: var(--level-2);
            border-radius: 6px;
        }


    }

    .active {
        background-color: var(--level-8);
        border-radius: 6px;
        width: 100%;

        img {
            filter: invert(100%);
            cursor: pointer;
        }

        span,
        a {
            color: white;
            padding: 5px;
            font-weight: 600;

        }


    }

}