.footer_version {
    display: flex;
    justify-content: flex-end;

    span {
        margin-right: 20px;
        font-family: $eduate-number-font;
        font-size: $eduate-default-font-size;
    }
}

footer {
    span {
        position: relative;
        right: 20px;
    }

    background-color: var(--level-5);
}

.footer__lost-login {
    display: flex;
    justify-content: flex-start;

    span {
        font-family: $eduate-font-family;
        font-size: $eduate-default-font-size;
        position: relative;
        left: 80px;
    }
}