.create-tags {
    height: calc(100% - 165px);
    margin: 0px 20px;

    &__block {
        height: calc(100% - 55px);
        column-gap: 30px;
        margin-top: 10px;

        .booktype-left,
        .booktype-right {
            margin: 0px !important;
        }
    }

    &__tableblock {
        height: calc(100% - 45px);
        margin-top: 10px
    }

    &__table {
        @include table();

        &--slno {
            width: calc(100% - 90%);
            font-family: $eduate-number-font;
        }

        &--desc {
            width: calc(100% - 70%);
        }
    }
}