.addressbar {
    background: var(--level-2);
    height: 30px;
    // color: var(--text-color) !important;
    padding: 2px 0px 0px 0px;

    &__slide-in {
        white-space: nowrap;
        overflow: hidden;
        width: 100%;

        span {
            display: inline-block;
            animation: addressbar__slide-in 35s linear infinite;
            font-family: $eduate-font-family;
            font-size: 16px;
            color: var(--text-color);
            font-weight: 700;
            width: 100%;
        }
    }

    @keyframes addressbar__slide-in {
        0% {
            transform: translateX(100%);
        }

        100% {
            transform: translateX(-100%);
        }
    }
}