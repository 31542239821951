.assign-user-rights {
    height: calc(100% - 35px);

    &__title {
        img {
            @include closeIconInModal();
        }
    }

    &__select-options {
        column-gap: 20px;
        height: 125px;
        margin-top: 5px;



        border-bottom: 1.8px dashed var(--border-color);
    }

    &__textfield {
        @include textfield();
        margin-top: 10px !important;

        &--date {
            @extend .assign-user-rights__textfield;
            width: fit-content !important;
        }
    }

    &__colspan {
        column-count: 2;
        column-gap: 20px;
        height: 100%;
    }

    &__datablock {
        margin-top: 10px;
        height: calc(100% - 180px);
        column-gap: 20px;

        &--left {
            border: 1px solid var(--border-color);
            padding: 10px;
            height: 100%;
            border-radius: 5px;
        }

        &--right {
            border: 1px solid var(--border-color);
            padding: 10px;
            height: 100%;
            border-radius: 5px;
        }

        &--ul {
            height: calc(100% - 70px);
            margin-top: 10px;
            overflow: auto;

            li {
                .MuiSwitch-root {
                    margin-top: 5px;
                }

                display: flex;
                justify-content: space-between;
                padding: 5px;
                border-bottom: 1px solid var(--border-color);
            }

            &--module-name {
                display: flex;
                column-gap: 5px;
                font-size: 14px;
                font-family: $eduate-font-family;
            }
        }

        &--title {
            display: flex;
            gap: 10px;
            margin-top: 10px;
            background-color: var(--level-2);
            border-radius: 6px;
            padding: 3px;

            b {
                font-family: $eduate-font-family;
                font-size: 16px;
                margin-top: auto;
            }
        }
    }

    &__edit {
        height: 100%;

        &--title {
            display: flex;
            column-gap: 10px;

            .MuiSwitch-root {
                margin-top: 5px;
            }

            b {
                font-size: 16px;
            }

            .modal-close-icon {
                @include closeIconInModal()
            }
        }

        &--select {
            margin-top: 10px;
        }

        &--icons {
            display: flex;
            justify-content: center;
            column-gap: 30px;
            margin: 10px 0px 10px 0px;

            img {
                background-color: var(--level-5);
                padding: 3px;
                border-radius: 6px;

                img {
                    width: 35px !important;
                }
            }

            span {
                display: block;
                text-align: center;
                font-size: 14px;
                font-family: $eduate-font-family;
                position: relative;
                top: 10px;
            }

            &--block {
                &--view {
                    img {
                        padding: 4px;
                    }
                }

                &--edit {
                    img {
                        width: 35px !important;
                    }
                }
            }
        }
    }

    &__tableblock {
        height: calc(100% - 30px);

        &--table {
            @include table();

            &--slno {
                width: calc(100% - 92%);
                font-family: $eduate-number-font;
            }

            &--th-slno {
                width: calc(100% - 92%);
            }

            &--actions {
                width: calc(100% - 90%);
            }
        }
    }

    &__for-multiple {
        height: 100%;

        &--title {
            img {
                @include closeIconInModal()
            }
        }

        &--tableblock {
            height: calc(100% - 120px);
            margin-top: 10px;
        }

        &--table {
            @include table();
            @include tableCheckbox();

            &--slno {
                width: calc(100% - 92%);
                font-family: $eduate-number-font;
            }
        }
    }
}

.assign-user-rights__datablock--ul::-webkit-scrollbar {
    @include scrollbar();
}

.assign-user-rights__datablock--ul::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}

.assign-user-rights__datablock--ul::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}