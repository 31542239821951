.cust-add {
    height: 100%;

    &__title {

        img {
            @include closeIconInModal();
        }
    }

    &__form {
        height: calc(100% - 75px);
        overflow: auto;
        column-gap: 40px;

        &--header {
            @include themeColorHeadersInForm();
        }

    }
}

//table scroll
.cust-add__form::-webkit-scrollbar {
    @include scrollbar();
}

.cust-add__form::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}

.cust-add__form::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}