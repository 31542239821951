.chat-channel {
    @include mainFrame();

    &__frame {
        @include layout();
    }

    &__select {
        column-gap: 10px;

        &--textfield {
            @include textfield();
        }
    }

    &__data-block {
        height: calc(100% - 135px);
        border: 1px solid var(--border-color);
        border-radius: 6px;
        margin-top: 10px;

        &--frame {
            height: calc(100% - 40px);
            display: flex;
            column-gap: 5px;
        }

        &--left {
            height: 100%;
            width: 100%;
        }

        &--checked-total {
            background-color: map-get($grey, level_6);
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 250px;

            span {
                font-family: $eduate-font-family;
                font-size: $eduate-default-font-size;
                color: map-get($colors, white);
                padding-right: 5px;
                font-weight: 500;
            }

            img {
                filter: invert(100%);
            }
        }

        &--title {
            display: flex;
            height: 40px;
            justify-content: space-between;
            align-items: center;
            border: 1px solid var(--border-color);
            background: map-get($cool-grey, level_2);
            padding: 0px 5px;
            backdrop-filter: blur(16.133333206176758px);

            .storybook-input {
                margin-top: 0px;
                width: 150px;
            }
        }

        &--flex {
            display: flex;
            column-gap: 5px
        }

        &--textfield {
            @include textFieldInForms();
            margin-top: 0px !important;
            background-color: var(--bg-color) !important;
            width: fit-content !important;
        }

        &--compare {
            width: 20px;
        }

        &--total {
            display: flex;
            column-gap: 5px;
            margin-left: 10px;
            justify-content: flex-end;
            align-items: center;


            &--images {
                display: flex;
                align-items: center;

                img {
                    width: 20px;
                    margin-left: -5px;
                }
            }

            span {
                color: var(--text-color);
                font-family: $eduate-font-family;
                font-size: $eduate-default-font-size;

                &:hover {
                    font-weight: 700;
                    text-decoration: underline;
                }
            }
        }
    }
}

#members-list {
    display: none;
}

.chat-channel__data-block--right {
    width: 0;
    display: none;
    transition: width 0.3s ease-out;
}

.shift-left {
    width: calc(100% - 250px) !important;
    border-right: 1px solid var(--border-color);
}

.expand-right {
    width: 250px !important;
    display: flex;
}