@import "../../scss/common/fonts";
@import "../../scss/common/colors";

.Heading {
  font-family: $eduate-font-family;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 22px !important;
  color: var(--text-color) !important;
  text-align: center;
  height: 30px;
  position: sticky;
  position: -webkit-sticky;
}

.subtitle2 {
  font-size: 14px !important;
  margin-top: 0px !important;
  font-weight: 500 !important;
  height: 22px;
  color: map-get($grey, level_6) !important;
}

.subtitle1 {
  text-align: center;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  font-size: 16px !important;
  color: map-get($grey, level_7) !important;
  font-weight: 400px !important;
  font-family: $eduate-font-family;
  font-style: normal;
  height: 25px;

  b {
    color: var(--level-4) !important;
  }
}