.choose-message-template {
  height:100%;
  &__tableblock {
    height: calc(100% - 85px);
    margin-top: 10px;
  }
  &__table {
    @include table();
    &--slno{
      width: calc(100% - 94%);
      font-family: $eduate-number-font;
    }
  }
  &__title{
    img{
      @include closeIconInModal();
    }
  }
}
