.cust-list {
  @include mainFrame();

  &__frame {
    @include layout();
  }

  &__select {

    .storybook-button,
    .storybook-input {
      margin-top: 0px;
    }

    &--button {
      @include addButton();
    }
  }

  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 125px);
  }
}