nav {
    height: 30px;
}

.menus {
    display: flex;
    list-style: none;
    background-color: var(--level-6);
    height: 30px;
    border-radius: 0px 0px 25px 0px;
    font-family: $eduate-font-family;
}

.menu-items {
    position: relative;
    font-size: $eduate-default-font-size;
    color: map-get($colors, black);

    &--li {
        border-bottom: 2px solid var(--border-color);
    }

    a {
        display: block;
        font-size: inherit;
        color: inherit;
        text-decoration: none;
        padding: 4px;

    }

    &__submenu {
        color: map-get($colors, black);

        &:hover {
            color: map-get($colors, white) !important;
            background-color: var(--level-4) !important;
            border-radius: 6px;
        }

        &:focus {
            color: map-get($colors, white) !important;
            background-color: var(--level-5) !important;
        }
    }

    &__mainmenu {
        color: map-get($colors, map-get($colors, white));

        &:focus {
            color: var(--level-5);
            background-color: map-get($colors, white);
            outline: none;
            box-shadow: none;
            border-style: none;
        }

        &:active {
            color: var(--level-5);
            background-color: map-get($colors, white);
            outline: none;
            box-shadow: none;
            border-style: none;
        }
    }

    &__rightarrow {
        float: right;
    }

    button:active {
        color: var(--level-5);
        background-color: map-get($colors, white);
        border-radius: 0px;
        outline: none;
        box-shadow: none;
        border-style: none;
    }

    button {
        font-size: $eduate-default-font-size;
        border-radius: 0px;
        background-color: transparent;
        cursor: pointer;
        width: 100%;
        height: 30px;
        text-align: left;
        padding: 2px 5px;
    }

    .arrow::after {
        content: "";
        display: inline-block;
        margin-left: 0.28em;
        vertical-align: 0.09em;
        border-top: 0.42em solid;
        border-right: 0.32em solid transparent;
        border-left: 0.32em solid transparent;
    }
}

.dropdown {
    position: absolute;
    right: 0;
    left: auto;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
        0 4px 6px -2px rgba(71, 63, 79, 0.16);
    z-index: 9999;
    padding: 5px;
    list-style: none;
    background-color: map-get($colors, white);
    display: none;
    width: 285px;

    li:hover {
        background-color: var(--level-4);
        color: map-get($colors, white);
        border-radius: 6px;
    }

    button {
        font-size: $eduate-default-font-size;
        border-radius: 0px;
        background-color: transparent;
        cursor: pointer;
        width: 100%;
        height: fit-content !important;
        text-align: left;
        padding: 2px 5px;

        &:focus {

            outline: none;
            box-shadow: none;
        }

        &:active {

            outline: none;
            box-shadow: none;
            border-style: none;
        }
    }
}

.dropdown.show {
    display: block;
    left: 0px;
    padding: 5px !important;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1),
        0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4);
}

.dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
}