.message-template {
  @include mainFrame();
  display: flex;
&__frame{
  @include layout();
}

  &__filters {
    column-gap: 10px;

    &--button {
      display: flex !important;
      justify-content: flex-end !important;
      margin-right: 0px !important;
      margin-left: auto !important;
    }
  }

  &__datablock {
    margin-top: 10px;
    height: calc(100% - 135px);
    border: 1px solid var(--border-color);
    overflow: auto;

    &--li {
      display: flex;
      padding: 15px;
      border-bottom: 1px dashed var(--border-color);

      &--message {
        display: flex;
        width: calc(100% - 210px);
        font-size: $eduate-default-font-size;
        font-family: $eduate-font-family;
      }

      &--image {
        width: 60px;
        display: flex;
        justify-content: center;
        column-gap: 10px;
      }

      &--module {
        width: 150px;
        font-size: 12px;
        font-family: $eduate-font-family;
        border-right: 1px dashed var(--border-color);
        border-left: 1px dashed var(--border-color);
        text-align: center;
        margin: auto;
      }
    }
  }

  &__predefined-words {
    margin-top: 10px;
    height: calc(100% - 155px);
    overflow: auto;

    button {
      background-color: var(--level-2);
      padding: 5px 10px;
      border-radius: 30px;
      font-family: $eduate-font-family;
      font-size: $eduate-default-font-size;
      margin: 5px;
    }
  }

  &__typed-text {
    margin-top: 20px;
    height: 80px;
    overflow: auto;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    padding: 5px;
    font-family: $eduate-font-family;
    font-size: $eduate-default-font-size;
  }
}

.highlight {
  background-color: var(--level-1);
  border-radius: 20px;
  padding: 10px;
  text-transform: uppercase;
  color: var(--level-5);
  font-weight: 600;
}

.message-template__datablock::-webkit-scrollbar,
.message-template__typed-text::-webkit-scrollbar,
.message-template__predefined-words::-webkit-scrollbar {
  @include scrollbar();
}

.message-template__datablock::-webkit-scrollbar-thumb,
.message-template__typed-text::-webkit-scrollbar-thumb,
.message-template__predefined-words::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.message-template__datablock::-webkit-scrollbar-thumb:hover,
.message-template__typed-text::-webkit-scrollbar-thumb:hover,
.message-template__predefined-words::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
