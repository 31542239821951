.studentregistration {
    height: 100%;
    &__form {
        height: calc(100% - 30px);
        &--details{
            column-gap: 20px;
            height:calc(100% - 45px);
            overflow: auto;

        }
    }
    &__title{
        img{
            @include closeIconInModal();
        }
    }
  
}