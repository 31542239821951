.inst-details {
    height: calc(100% - 110px);

    &__update {
        height: calc(100% - 10px);
        margin-top: 5px;
    }

    &__steps {
        @include muiStepHeader();
        margin: 5px 0px 5px 0px;
    }

    &__form {
        height: calc(100% - 65px);
        overflow: auto;
        column-gap: 40px;

        &--header {
            @include themeColorHeadersInForm();
        }

        &--title {
            img {
                @include closeIconInModal();
            }
        }

        &--frame-left {
            height: calc(100% - 10px);
            border: 1px solid var(--border-color);
            padding: 5px;
            border-radius: 0px 30px 0px 0px;
            margin-top: 10px
        }

        &--frame-right {
            @extend .inst-details__form--frame-left;
            border-radius: 30px 0px 0px 0px;
        }

    }

    &__license {
        width: 100%;
        height: 160px;
        background-color: map-get($cool-grey, level_3);
        border-radius: 6px;
        padding: 5px;

        &--flex {
            display: flex;
            justify-content: center;
            column-gap: 10px;
            align-items: center;
            margin-top: 10px;

            img {
                width: 20px;
            }
        }

        &--s-date {
            @include textFieldInForms();
            width: fit-content;
            background-color: var(--bg-color);

            input {
                color: map-get($Green, level_5) !important;
                font-weight: 700;
            }
        }

        &--e-date {
            @include textFieldInForms();
            width: fit-content;
            background-color: var(--bg-color);

            input {
                color: map-get($red, level_5) !important;
                font-weight: 700;
            }
        }

        &--g-period {
            @include textFieldInForms();
            width: fit-content;
            background-color: var(--bg-color);

            input {
                color: map-get($amber, level_5) !important;
                font-weight: 700;
            }
        }

        &--total {
            margin-top: 10px;
            text-align: center;

            b {
                font-family: $eduate-number-font;
                font-size: $eduate-default-font-size;
                color: map-get($cool-grey, level_7);
            }

            span {
                font-family: $eduate-font-family;
                font-size: $eduate-default-font-size;
                color: map-get($cool-grey, level_7);
            }
        }

        &--tableblock {
            height: calc(100% - 235px);
            margin-top: 10px;
        }

        &--table {
            @include table();

            &--slno {
                width: calc(100% - 96%);
                font-family: $eduate-number-font;
            }

            &--actions {
                width: calc(100% - 92%);
            }
        }
    }

    &__academics {
        &--add {
            height: calc(100% - 30px);
        }

        &--list {
            height: calc(100% - 75px);

            &--modal {
                height: calc(100% - 75px);
            }
        }

    }

    &__config {
        height: calc(100% - 150px);
    }

}

.inst-details__form::-webkit-scrollbar {
    @include scrollbar();
}

.inst-details__form::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}

.inst-details__form::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}