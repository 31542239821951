@import "../common/colors";

@mixin layout {
  margin: 0px 20px 0px 20px;
  width: calc(100% - 210px);
}

@mixin mainFrame {
  height: calc(100% - 130px);
  display: flex;
}

@mixin addButton {
  display: block;
  margin-left: auto !important;
  margin-right: 0px !important;
}

@mixin viewButton {
  background-color: map-get($fuchsia, level_7);
  color: map-get($colors, white);
  border-radius: 30px;
  font-size: 12px;
  font-family: $eduate-font-family;
  padding: 2px 10px;

  img {
    width: 20px;
  }
}

@mixin themeColorHeadersInForm {
  background: var(--level-5);
  width: 100%;
  height: 35px;
  color: white;
  border-radius: 6px;
  font-family: $eduate-font-family;
  font-weight: 500;

  h2 {
    font-family: $eduate-font-family !important;
    font-size: 18px;
    text-align: center;
    line-height: 36px;
    font-weight: 500;
  }
}

@mixin closeIconInModal {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 5px;
  right: 0px;

  &:hover {
    background-color: map-get($cool_grey, level_2);
  }
}

@mixin muiStepHeader {
  .MuiStepLabel-iconContainer .Mui-active {
    color: var(--level-5) !important;

    .MuiStepIcon-text {
      fill: white !important;
    }
  }

  .MuiStepIcon-root {
    color: map-get($grey, level_3) !important;
  }

  .MuiStepIcon-text {
    fill: map-get($colors, black) !important;
  }

  .MuiStepLabel-iconContainer .Mui-completed {
    color: var(--level-5) !important;
  }

  .MuiStepConnector-line {
    border-color: var(--level-6) !important;
  }

  .MuiStepLabel-alternativeLabel {
    color: var(--text-color) !important;
    font-family: $eduate-font-family;
  }

  .MuiTypography-root {
    font-family: $eduate-font-family;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    color: var(--text-color);
  }

  .MuiSvgIcon-root {
    width: 30px !important;
    height: 30px !important;
  }
}

@mixin scrollbar {
  width: 18px;
  background-color: map-get($cool_grey, level_2);
}

@mixin scrollbarthumb {
  border: 5px solid map-get($cool_grey, level_2);
  background-color: map-get($cool_grey, level_4);
  border-radius: 30px;
  padding: 5px;
}

@mixin scrollbarthumb-hover {
  border: 3px solid map-get($cool_grey, level_2);
}

@mixin textFieldInForms {
  height: 30px;
  width: 100%;
  margin-top: 10px !important;

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid var(--level-3) !important;
  }

  .MuiInputBase-input {
    padding: 5px !important;
  }

  input {
    font-size: 14px !important;
    font-family: $eduate-font-family;
    padding-left: 5px !important;
    color: map-get($grey, level_8);
    font-weight: 400 !important;
  }

  label {
    font-family: $eduate-font-family;
    font-size: 16px !important;
    color: var(--text-color);
    z-index: 8;
  }
}

@mixin bookTypeFrameStyle {
  column-gap: 40px;

  &--left {
    height: 100%;
    border-radius: 0px 30px 0px 0px;
    border: 1px solid var(--border-color);
    padding: 5px;
  }

  &--right {
    height: 100%;
    border-radius: 30px 0px 0px 0px;
    border: 1px solid var(--border-color);
    padding: 5px;
  }
}

@mixin table {
  max-height: 100%;

  th {
    background-color: var(--level-8);
    padding: 0px 2px;
    color: map-get($colors, white);
    text-align: center;
    border-right: 1px solid var(--border-color) !important;
    font-family: $eduate-font-family;
  }

  td {
    padding: 5px 3px;
    font-style: normal;
    font-size: 12px;
    font-family: $eduate-font-family;
    border-right: 1px solid var(--border-color) !important;
    border-bottom: 1px solid var(--border-color) !important;

    color: var(--text-color);

    img,
    a {
      &:hover {
        cursor: pointer;
      }
    }
  }

  tbody {
    background-color: var(--level-1);

    tr {
      &:hover {
        background-color: var(--level-2);

        td {
          color: var(--level-7);
        }
      }
    }
  }
}

@mixin textfield {
  margin-top: 10px !important;
  width: 100%;

  .MuiInputBase-input {
    padding: 5px !important;
    margin: 1px !important;
  }

  input {
    font-size: 14px !important;
    font-family: $eduate-font-family;
    padding-left: 5px !important;
    color: var(--text-color) !important;
    font-weight: bold !important;
  }

  label {
    font-family: $eduate-font-family;
    font-size: 14px !important;
    color: map-get($grey, level_6) !important;
    z-index: 8;
  }

  .MuiInputBase-root .Mui-disabled {
    background-color: var(--disabled-color) !important;
    opacity: 1;
    -webkit-text-fill-color: var(--text-color) !important;
    font-weight: bold;
    z-index: 5;
  }

  .MuiOutlinedInput-notchedOutline {
    padding: 8px !important;
    border-color: var(--border-color) !important;
  }
}

@mixin muiTabsForLogin {
  .MuiTab-root {
    font-family: $eduate-font-family;
    font-size: 14px !important;
    text-transform: capitalize !important;
    min-height: 20px !important;
    padding: 8px !important;
    color: map-get($grey, level_4) !important;
    font-weight: 700;
    border-radius: 30px;
  }

  .MuiTabs-root {
    min-height: 20px !important;
  }

  .Mui-selected {
    color: var(--level-6) !important;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
      0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-radius: 30px;
    background-color: var(--bg-color);
  }

  .MuiTabs-indicator {
    display: none !important;
  }

  .MuiTabs-flexContainer {
    background: map-get($grey, level_9);
    padding: 5px;
    border-radius: 30px;
    width: fit-content !important;
  }
}

@mixin textfieldInForms {
  height: 30px;
  width: 100%;
  margin-top: 10px !important;

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid var(--level-3) !important;
  }

  .MuiInputBase-input {
    padding: 5px !important;
  }

  input {
    font-size: 14px !important;
    font-family: $eduate-font-family;
    padding-left: 5px !important;
    color: map-get($grey, level_8) !important;
    font-weight: 400 !important;
  }

  label {
    font-family: $eduate-font-family;
    font-size: 16px !important;
    color: var(--text-color) !important;
    z-index: 8;
  }
}

@mixin studentProfile {
  img {
    height: calc(100% - 10px);
    width: 100%;
    border-radius: 6px;
    border: 1px solid var(--border-color);
    padding: 2px;
  }
}

@mixin blocksInDashboard {
  height: fit-content;
  border-radius: 10px;
  border: 1px solid map-get($grey, level_3);
  transition: transform 0.2s;
  padding: 5px;

  &:hover {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
      0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  }
}

@mixin tableCheckbox {
  .css-1q1u3t4-MuiTableRow-root.Mui-selected {
    background-color: var(--level-4) !important;
  }

  th {
    .MuiSvgIcon-root {
      fill: map-get($colors, white) !important;
      width: 24px !important;
      height: 24px !important;
      font-size: 14px !important;
    }
  }

  td {
    .MuiSvgIcon-root {
      width: 22px !important;
      height: 22px !important;
      font-size: 14px !important;
    }
  }
}

@mixin browseFiles {
  background: linear-gradient(90deg, #ec008c 0%, #fc6767 100%);
  font-family: "Josefin Sans" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  border: 0;
  border-radius: 6px;
  margin: 10px 0px 0px 0px;
  color: white;
  padding: 5px;
  text-transform: capitalize;
  height: 30px;

  &:hover {
    cursor: pointer;
  }

  input {
    display: none;
  }

  img {
    padding-right: 5px;
  }
}

@mixin muiChipToShowDates {
  .MuiChip-root {
    background-color: var(--bg-color) !important;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
      0px 4px 6px -1px rgba(0, 0, 0, 0.1);
    color: var(--level-5);
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
  }
}

// mui-tabs
@mixin muiTabs {
  .MuiTab-root {
    font-family: $eduate-font-family;
    font-size: 14px !important;
    text-transform: capitalize !important;
    min-height: 20px !important;
    padding: 8px !important;
    color: var(--text-color) !important;
    font-weight: 700;
    border-radius: 30px;
  }

  .MuiTabs-root {
    min-height: 20px !important;
  }

  .Mui-selected {
    color: var(--level-6) !important;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
      0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-radius: 30px;
    background-color: var(--bg-color);
  }

  .MuiTabs-indicator {
    background-color: var(--level-1) !important;
  }

  .MuiTabs-flexContainer {
    background: var(--level-1);
    padding: 5px;
    border-radius: 30px;
    width: fit-content !important;
  }
}

@mixin tdContainsOnclick {
  text-decoration: underline;

  cursor: pointer;
}
