@import "../common/mixin";

.td-sl-no {
  width: 80px;
  font-family: $eduate-number-font;
}

.td-adm-no {
  width: 180px;
  font-family: $eduate-number-font;
}
.td-name {
  width: 200px;
}

.td-desc {
  width: 150px;
}

.td-mobile {
  width: 180px;
  font-family: $eduate-number-font;
}

.td-email {
  width: 180px;
}

.td-date {
  width: 100px;
  font-family: $eduate-number-font;
}

.td-year {
  width: 100px;
  font-family: $eduate-number-font;
}
.td-amount {
  width: 150px;
  font-family: $eduate-number-font;
}

//table scroll

.MuiTableContainer-root::-webkit-scrollbar,
.MuiDataGrid-virtualScroller::-webkit-scrollbar,
.ql-editor::-webkit-scrollbar {
  @include scrollbar();
}

.MuiTableContainer-root::-webkit-scrollbar-thumb,
.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb,
.ql-editor::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.MuiTableContainer-root::-webkit-scrollbar-thumb:hover,
.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover,
.ql-editor::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
