 .create-feeds {
     height: 100%;

     .label-grid {
         grid-template-columns: .4fr 1fr !important;
     }

     &--attachments {
         border: 1px solid var(--border-color);
         padding: 5px;
         margin-top: 10px;
         border-radius: 6px;
         min-height: 35px;

         li {
             font-family: $eduate-font-family;
             font-size: 14px;
         }
     }

     &--data {
         height: calc(100% - 75px);
     }

     &--title {
         img {
             @include closeIconInModal();
         }
     }

     &--selected {
         text-align: center;

         span {
             font-family: $eduate-number-font;
             font-size: 12px;
             color: map-get($grey, level_7);
         }
     }

     &--logo-grid {
         display: grid;
         grid-template-columns: 0.8fr 1.5fr 0.6fr;
         gap: 10px;
     }

     &--browse {
         @include browseFiles();
         margin: 10px 10px 0px 0px;

         &--input {
             display: none;
         }
     }

     .quill {
         border: 1px solid var(--border-color) !important;
         margin-top: 10px !important;
         height: 150px;
     }

     .ql-container {
         height: calc(100% - 30px) !important;
     }
 }

 .delete-modal {
     &__title {
         .close-icon {
             @include closeIconInModal();
         }
     }

     &__body {
         font-size: $eduate-default-font-size;
         font-family: $eduate-font-family;
     }
 }