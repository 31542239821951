@import "../../scss/common/fonts";
@import "../../scss/common/colors";

.storybook-input {
  border-radius: 6px;
  border: 1px solid var(--border-color);
  background-color: var(--bg-color);
  width: 100%;
  height: 30px;
  margin-top: 10px;
  padding: 0px 5px;
  font-size: $eduate-default-font-size;
  font-family: $eduate-font-family;
  color: var(--text-color);
}

.storybook-input:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #fbb6ce !important;
}

.large {
  height: 4.5rem;
  font-size: 1.25rem;
  width: 100%;
}

.medium {
  width: 70%;
}

.field_error::placeholder {
  color: #c53030;
}

.field_error {
  border: 1.5px solid map-get($red, level_5) !important;
  box-shadow: 2px 0px 1px red !important;
}

.required_error {
  box-shadow: 3px 0px 0px red;
  width: calc(100% - 3px) !important;
}

/*Hide the arrow tag in type number*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* 
Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  font-family: $eduate-number-font;
}

.small {
  padding-left: 1rem;
  height: 25px;
  margin: 2px;
  padding: 10px;
  font-size: $eduate-default-font-size;
}

.storybook-input[disabled] {
  background-color: var(--disabled-color);
  color: var(--text-color);
  cursor: not-allowed;
}

input[type="date"] {
  width: fit-content !important;
}

input[type="time"] {
  width: fit-content !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: var(--img-color);
}

.storybook-input[type="checkbox"] {
  width: 22px !important;
  height: 22px !important;
  border-radius: 3px !important;
}

input[type="checkbox"]:checked {
  background-color: var(--level-8);
  border-color: var(--level-8);
}
