.chat-home {
  height: calc(100% - 130px);
  display: flex;

  &__frame {
    @include layout();

    &--data {
      height: calc(100% - 30px);
    }
  }

  &__select {
    column-gap: 10px;

    &--button {
      @include addButton();
    }
  }

  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 105px);
  }

  &__table {
    @include table();

    &--view-button {
      @include viewButton();
    }

    &--actions {
      width: calc(100% - 90%);
    }
  }
}