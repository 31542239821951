.mobile-login__proceed {
  height: calc(100% - 40px);

  &--title {
    text-align: center;
    font-family: $eduate-font-family;
    margin-top: 10px;
  }
  span {
    font-size: 16px;
    font-family: $eduate-font-family;
    margin-top: 10px;
    display: block;
    text-align: center;
  }
  b{
    font-size: 16px;
    font-family: $eduate-number-font;
    margin-top: 10px;
    display: block;
    text-align: center;
  }
  &--image{
    width: 60px;
    height: 60px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
  &--gif{
    width: 150px;
    height: 150px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;

    img{
        width: 100%;
    }
    
  }
}
