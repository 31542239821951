.student-preview {
  height: fit-content;
  &__title {
    img {
      @include closeIconInModal();
    }
  }

  &__details {
    height: 140px;
    column-gap: 20px;
    margin-top: 10px;
    border-bottom: 1.8px dashed var(--border-color);
    &--textfield {
      @include textfield();
      margin-left: 0px !important;
      margin: 10px 0px 0px 0px !important;
    }
    &--date {
      @include textfield();
      margin-left: 0px !important;
      margin: 10px 0px 0px 0px;
      width: fit-content !important;
    }
  }
  &__image {
    @include studentProfile();
    margin-top: 0px !important;
    height: 100% !important;
  }
}
