.inst-preview {
    height: 100%;

    &__title {
        img {
            @include closeIconInModal()
        }
    }

    &__frame {
        height: calc(100% - 80px);
        display: flex;
        column-gap: 20px;
        margin-top: 5px;

        &--basic {
            height: 100%;
            width: calc(100% - 450px);
            padding: 5px;
            border-radius: 6px;
            border: 1px solid var(--border-color);

            &--logo {
                margin-top: 10px;
                display: flex;
                justify-content: center;

                img {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    height: 50px;
                    width: 50px;
                }
            }

            &--data {
                height: calc(100% - 80px);
                overflow: auto;
                padding: 5px;

                &--textfield {
                    @include textfield();
                    width: 100%;
                }
            }
        }

        &--license {
            width: 200px;

            &--image {
                width: 40px;
                height: 40px;
                display: block;
                margin-left: auto;
                margin-right: auto;
            }

            &--active-acd-yr {
                height: 100px;
                background-color: var(--level-2);


                &--title {
                    height: 20px;

                }

                &--footer {
                    background-color: map-get($cool-grey, level_2);
                    color: var(--level-5);
                    font-weight: 700;
                    font-size: $eduate-default-font-size;
                    height: 30px;
                    margin-top: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            &--status {
                margin-top: 10px;
                background-color: var(--level-2);
                padding: 5px;
                border-radius: 4px;


                &--compare {
                    width: 25px;
                    height: 25px;
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                    margin-top: 5px;
                }

                &--textfields {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;

                }

                &--start {
                    margin-top: 10px !important;
                    width: 100%;

                    .MuiInputBase-input {
                        padding: 5px !important;
                    }

                    input {
                        font-size: 14px !important;
                        font-family: $eduate-font-family;
                        padding-left: 5px !important;
                        color: map-get($Green, level_5) !important;
                        font-weight: bold !important;
                    }

                    label {
                        font-family: $eduate-font-family;
                        font-size: 14px !important;
                        font-weight: 700;
                        color: map-get($grey, level_6) !important;
                        z-index: 8;
                    }

                    .MuiInputBase-root .Mui-disabled {
                        background-color: var(--bg-color) !important;
                        -webkit-text-fill-color: map-get($Green, level_5) !important;
                        font-weight: bold;

                    }

                    .MuiOutlinedInput-notchedOutline {
                        padding: 8px !important;
                        border-color: var(--border-color) !important;
                    }

                    width: fit-content !important;
                    justify-content: center;
                    display: flex;

                    .MuiInputBase-root {
                        background-color: var(--bg-color) !important;
                    }
                }

                &--end {
                    @extend .inst-preview__frame--license--status--start;

                    .MuiInputBase-root .Mui-disabled {

                        -webkit-text-fill-color: map-get($red, level_6) !important;
                        font-weight: bold;

                    }

                    color: map-get($red, level_5) !important;
                }

                &--days {
                    color: map-get($cool-grey, level_6);
                    font-size: $eduate-default-font-size;
                    font-family: $eduate-font-family;

                    b {
                        font-size: 16px;
                    }


                }

                &--active {
                    background-color: var(--bg-color);
                    display: flex;
                    align-items: center;
                    padding: 5px;
                    font-size: $eduate-default-font-size;
                    font-family: $eduate-font-family;
                    height: 35px;

                    img {
                        width: 20px;
                        margin-right: 10px;
                    }
                }
            }

        }

        &--modules {
            width: 250px;
            border: 1px solid var(--border-color);
            border-radius: 4px;
            padding: 5px;

            img {
                height: 40px;
                width: 40px;
                display: block;
                margin-left: auto;
                margin-right: auto;
            }

            &--list {
                height: calc(100% - 75px);
                overflow: auto;


                li {
                    padding: 5px;
                    background-color: var(--level-1);
                    font-family: $eduate-font-family;
                    font-size: $eduate-default-font-size;
                    border-bottom: 1px dashed var(--border-color);
                }
            }
        }


    }
}

.inst-preview__frame--basic--data::-webkit-scrollbar,
.inst-preview__frame--modules--list::-webkit-scrollbar {
    @include scrollbar();
}


.inst-preview__frame--basic--data::-webkit-scrollbar-thumb,
.inst-preview__frame--modules--list::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}


.inst-preview__frame--basic--data::-webkit-scrollbar-thumb:hover,
.inst-preview__frame--modules--list::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}