.channel-student-data {
    height: 120px;
    margin-top: 10px;
    column-gap: 10px;

    &__profile {
        height: 100%;
        @include studentProfile();

        img {
            margin-top: 10px;
        }

    }

    &__textfield {
        @include textfield();
        margin-top: 10px !important;
    }

    &__flex {
        column-gap: 20px;
        column-count: 2;
    }


}