.mobile-login {
    margin: 20px;
    height: calc(100% - 40px);
    background-color: map-get($blue, level_1);
    &__back {
        a {
            color: map-get($cool-grey, level_6);
            font-size: 14px;
            font-family: $eduate-font-family;
            img {
                width: 20px;
                transform: rotate(90deg);
            }
        }
    }
    &__inst-details {
        background-color: map-get($cool-grey, level_2);
        border-radius: 6px;
        text-align: center;
        padding: 5px;
        height: 200px;
        img {
            width: 50px;
            height: 50px;
        }
        span,
        b {
            font-size: 12px;
            display: block;
            font-family: $eduate-font-family;
        }
    }

    &__main {
        margin-top: 10px;

        &--block {
            height: fit-content;
            background-color: map-get($blue, level_2);
            padding: 20px 10px;
            margin: 10px;
            position: relative;
            display: block;
            margin-top: auto;
            margin-bottom: auto;

            &--tabs {
                display: flex;
                justify-content: center;
                @include muiTabs();
                .MuiTab-root {
                    min-width: 60px !important;
                }
                .MuiButtonBase-root {
                    font-size: 10px !important;
                    padding: 3px !important
                    ;
                }
            }
            &--tabpanel{
                .storybook-button{
                    width: 100%;
                }
            }
        }
    }
    &__footer {
        position: absolute;
        overflow: hidden;
        text-align: center;
        bottom: 20px;
        height: 50px;
        left: 20px;
        right: 20px;
        background-color: map-get($cool-grey, level_2);
        width: calc(100% - 40px);
        padding: 10px;
        font-weight: 600;
        img {
            width: 25px;
            margin: 0px 10px;
        }
        span {
            font-size: 12px;
            font-family: $eduate-font-family;
            color: map-get($cool-grey, level_7);
        }
        b {
            font-family: $eduate-font-family;
        }
    }
    &__std-list {
        padding: 6px;
        height: calc(100% - 205px);
        overflow: auto;
        &--title {
            display: flex;
            justify-content: space-between;
            margin-top: 5px;
            h4 {
                font-family: $eduate-font-family;
            }
        }
        &--acn {
            &--list {
                box-shadow:
                    0px 2px 4px -1px rgba(0, 0, 0, 0.06),
                    0px 4px 6px -1px rgba(0, 0, 0, 0.1);
                padding: 2px;
            }
            &--title {
                font-family: $eduate-font-family;
                padding-top: 10px;
                display: grid;
                grid-template-columns: 1fr 1fr 0.1fr;
                button {
                    background-color: map-get($Green, level_6);
                    padding: 5px 8px;
                    color: white;
                    border-radius: 20px;
                    width: fit-content;
                    display: block;
                    margin-left: auto;
                    img {
                        width: 20px;
                        margin-right: 2px;
                    }
                }
            }
            &--grid {
                display: grid;
                grid-template-columns: 1fr 0.1fr;
                column-gap: 10px;
                &--divider {
                    height: 100%;
                    border-left: 1.8px dashed var(--border-color);
                    margin: 0 2px;
                }
                &--ledgerwise {
                    display: grid;
                    grid-template-columns: 0.1fr 1fr 0.1fr;
                    column-gap: 5px;
                    &--list {
                        @extend .mobile-login__pay-fee--block--acn--grid--ledgerwise;
                        column-gap: 10px;
                    }
                }
            }
            ul {
                margin-bottom: 0px;
                li {
                    display: flex;
                    justify-content: space-between;
                    span {
                        font-size: 14px;
                        font-family: $eduate-font-family;
                    }
                    b {
                        font-size: 14px;
                        font-family: $eduate-number-font;
                    }
                }
            }
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .mobile-login {
        .storybook-input {
            height: 40px;
        }
        .react-tel-input .form-control,
        .react-tel-input .flag-dropdown,
        input {
            height: 40px !important;
        }

        &__main {
            h4 {
                font-size: 18px;
            }
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .mobile-login {
        .storybook-input {
            height: 40px;
        }
        .react-tel-input .form-control {
            height: 40px !important;
            .flag-dropdown {
                height: 40px !important;
            }
        }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .mobile-login {
        .storybook-input {
            height: 45px !important;
        }
        .react-tel-input {
            height: 40px !important;
            .flag-dropdown {
                height: 40px !important;
            }
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 959px) {
    .mobile-login {
        .storybook-input {
            height: 40px !important;
        }
        .react-tel-input {
            height: 40px !important;
            .flag-dropdown {
                height: 40px !important;
            }
        }
    }
}

.mobile-login__std-list::-webkit-scrollbar {
    display: none;
}
