.student-list {
  @include mainFrame();

  &__frame {
    @include layout();
  }

  &__filters {
    column-gap: 10px;
  }

  &__button {
    @include addButton();
  }

  &__tableblock {
    height: calc(100% - 135px);
    border: 1px solid var(--border-color);
    margin-top: 10px;
  }

  &__li {
    display: flex;
    column-gap: 10px;
    font-size: $eduate-default-font-size;
    font-family: $eduate-font-family;

    img {
      width: 15px;
    }
  }
  .student-total-count {
    font-size: $eduate-default-font-size;
    font-family: $eduate-font-family;
    margin: 10px 20px 0px 20px;

    b {
      padding: 5px;
      font-family: $eduate-number-font;
      background: var(--level-5);
      border-radius: 2px;
      color: white;
    }
  }
}

.std_status {
  background-color: map-get($red, level_5);
  color: map-get($colors, white) !important;

  &:hover {
    background-color: map-get($red, level_5);
    color: map-get($colors, white) !important;
  }

  &--ne {
    background-color: map-get($yellow, level_5);
    color: map-get($colors, white) !important;

    &:hover {
      background-color: map-get($yellow, level_5);
      color: map-get($colors, white) !important;
    }
  }

  &--soa {
    background-color: map-get($sky, level_5);
    color: map-get($colors, white) !important;

    &:hover {
      background-color: map-get($sky, level_5);
      color: map-get($colors, white) !important;
    }
  }

  &--nxt {
    background-color: map-get($Green, level_5);
    color: map-get($colors, white) !important;
    width: calc(100% - 95%);

    &:hover {
      background-color: map-get($Green, level_5);
      color: map-get($colors, white) !important;
    }
  }

  &--det {
    background-color: map-get($true_grey, level_5);
    color: map-get($colors, white) !important;

    &:hover {
      background-color: map-get($true_grey, level_5);
      color: map-get($colors, white) !important;
    }
  }

  &--legends {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-right: 20px;
    column-gap: 5px;

    &--nxt {
      background-color: map-get($Green, level_5);
      border-radius: 999px;
      height: 30px;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      padding: 5px;
      width: 50px;
      color: map-get($colors, white);
    }

    &--det {
      @extend .std_status--legends--nxt;
      background-color: map-get($true_grey, level_5);
    }

    &--soa {
      @extend .std_status--legends--nxt;
      background-color: map-get($sky, level_5);
    }

    &--ne {
      background-color: map-get($yellow, level_5);
      @extend .std_status--legends--nxt;
    }

    &--tc {
      background-color: map-get($red, level_5);
      @extend .std_status--legends--nxt;
    }

    &--cur {
      @extend .std_status--legends--nxt;
      background-color: var(--level-2);
    }

    Label {
      margin-top: 6px;
      padding: 0px;
      font-size: 12px !important;
    }
  }
}
.student-total-count {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 5px;
  font-family: $eduate-font-family;
  margin-top: 5px;
  b {
    font-family: $eduate-number-font;
    font-size: 14px;
    background-color: var(--level-5);
    color: white;
    padding: 6px;
    border-radius: 6px;
  }
}
