.inst-edit {
    &__title {
        img {
            @include closeIconInModal();
        }
    }

    &__menus {
        display: flex;
        align-items: center;
        height: 100px;
        justify-content: center;
    }
}