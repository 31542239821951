.feeds {
    @include mainFrame();

    &__frame {
        @include layout();
    }

    &__data {
        height: calc(100% - 40px);
        @include muiChipToShowDates();

        &--list {
            height: calc(100% - 45px);
            border: 1px solid var(--border-color);
            padding: 5px;
            border-radius: 3px;
            overflow: auto;

            ul {
                height: 100%;
                margin-bottom: 0px !important;
            }

            &--content {
                height: 100%;
                width: 70%;
                display: block;
                margin-left: auto;
                margin-right: auto;
            }


        }
    }

    &__messages {
        height: calc(100% - 35px);
        overflow: auto;
        display: flex;
        padding: 5px 20px;

        ul {
            height: 100% !important;

        }

        &--list {
            display: flex;
            gap: 10px;
            border-radius: 6px;
            padding: 5px 10px;

            &--sender {
                border-radius: 6px;
                margin-right: 0% !important;
                // background-color: var(--level-2) !important;
                padding: 5px 10px;
                border-radius: 6px;
                width: 100%;
                border: 1px solid var(--border-color);

                &--li {
                    padding: 5px;
                }


            }

            &--time {
                font-family: $eduate-number-font;
                font-size: 12px;
                font-weight: 700;

                &:hover {
                    cursor: pointer;
                }

            }

            &--name {
                font-family: $eduate-font-family;
                font-size: 14px;
                width: 200px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            &--header {
                font-size: 20px;
                display: flex;
                justify-content: center;
                font-family: $eduate-font-family;
                color: var(--text-color)
            }

            &--flex {
                display: flex;
                justify-content: space-between;
            }

            &--image {
                width: 35px;
                height: 35px;
                margin-top: 5px;


            }

            &--tooltip {
                &--ul {
                    li {
                        cursor: pointer;
                        padding: 5px;
                        border-bottom: 1px dashed var(--border-color);

                        &:hover {
                            background-color: var(--level-1);
                        }
                    }
                }
            }
        }

        &--file-image {
            width: 100%;
            height: 240px;
        }

        p {
            font-family: $eduate-font-family;
            font-size: 14px;
            margin: 0px;
        }


    }

}


.feeds__data--list::-webkit-scrollbar,
.chat-channel-messages::-webkit-scrollbar {
    @include scrollbar();
}

.feeds__data--list::-webkit-scrollbar-thumb,
.chat-channel-messages::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}

.feeds__data--list::-webkit-scrollbar-thumb:hover,
.chat-channel-messages::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}