.copy-link {
    height: 100%;
    &__header {
        height: 40px;
        background-color: var(--disabled-color);
        img {
            @include closeIconInModal();
        }
    }
    &__data {
        height: calc(100% - 80px);
        margin-top: 10px;
        position: relative;
        padding: 0px 20px;
        &--image {
            width: 40px;
            height: 40px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            img {
                width: 100%;
            }
        }
        &--title {
            font-family: $eduate-font-family;
            text-align: center;
            color: map-get($sky, level_5);
        }
        &--text {
            height: calc(100% - 120px);
            overflow: auto;
            ul{
                margin-bottom: 0px;
                b{
                   display: flex;
                   justify-content: center;
                   font-family: $eduate-font-family;
                   font-size: 16px;
                }

            }

             li {
                p {
                    font-size:$eduate-default-font-size;
                    font-family: $eduate-font-family;
                    text-align: justify;
                }
              
            }
        }
        &--link {
            position: absolute;
            bottom: 5px;
            left: auto;
            right: auto;
            text-align: center;
span{
  
    color:var(--text-color) ;
    font-size: $eduate-default-font-size;
    font-family: $eduate-font-family;
    text-decoration: underline;
}
        }
    }
    &__footer {
        height: 30px;
        background-color: map-get($sky, level_5);
        color: map-get($colors, white);
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
        font-family: $eduate-font-family;
        font-size: $eduate-default-font-size;
        font-weight: 700;
    }
}




.copy-link__data--text::-webkit-scrollbar {
    @include scrollbar();
}


.copy-link__data--text::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}

.copy-link__data--text::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}