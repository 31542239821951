.std-fee-details {
    height: 100%;
    padding: 20px;
    &__block {
        height: calc(100% - 20px);
        background-color: map-get($blue, level_1);
        position: relative;
        &--header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 80px;
            background-color: map-get($cool-grey, level_3);
            padding: 0px 10px;
            &--l {
                display: flex;
                column-gap: 10px;
                font-family: $eduate-font-family;
                img {
                    width: 60px;
                    height: 60px;
                }
                span {
                    font-size: $eduate-default-font-size;
                    color: map-get($cool-grey, level_6);
                    display: block !important;
                }
                b {
                    font-size: 16px;
                    color: var(--text-color);
                    display: block;

                }
            }
            &--r {
                display: flex;
                align-items: center;
                column-gap: 5px;
                b {
                    color: #01366e !important;
                    font-size: 16px !important;
                    font-weight: 700 !important;
                }
                span {
                    color: map-get($cool-grey, level_6);
                    font-family: $eduate-font-family;
                    font-size: $eduate-default-font-size;
                }
                img {
                    width: 30px;
                    height: 30px;
                }
            }
     
       
        }
        &--data {
            margin-top: 40px;
            height: calc(100% - 120px);
            overflow: auto;
            a {
                font-size: 16px;
                color: map-get($cool-grey, level_6);
                font-family: $eduate-font-family;
                img {
                    width: 20px;
                    transform: rotate(90deg);
                }
            }
        }
        &--frame {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 400px;
            height: 120px;
            .storybook-button {
                width: 100% !important;
                margin-top: 20px !important;
            }
        }
        &--tableblock {
            height: calc(100% - 195px);
            margin-top: 10px;
            padding: 0px 10px;
            &--pay-fee {
                border-radius: 20px;
                background-color: map-get($Green, level_8);
                color: map-get($colors, white);
                font-size: 14px;
                font-family: $eduate-font-family;
            }
        }
        &--flex {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            padding: 0px 10px;
            &--total {
                display: flex;
                column-gap: 5px;
                span {
                    font-size: $eduate-default-font-size;
                    font-family: $eduate-font-family;
                }
                b {
                    font-size: $eduate-default-font-size;
                    font-family: $eduate-number-font;
                    color: map-get($sky, level_5);
                }
            }
        }
    }
    &__std-details {
        .student-preview__details {
            height: 135px !important;
        }
        padding: 0px 10px;
        &--cards {
            display: flex;
            column-gap: 20px;
            justify-content: center;
        }
        &--card {
            display: flex;
            justify-content: space-between;
            background-color: map-get($colors, white);
            padding: 10px 20px;
            border-radius: 6px;
            width: 300px;

            span {
                font-size: 14px;
                font-family: $eduate-font-family;
            }
            b {
                font-size: 16px;
                font-family: $eduate-number-font;
            }
        }
        &--frame {
            height: fit-content;
            width: 300px;
            background-color: map-get($cool-grey, level_2);
            border-radius: 6px;
            padding: 20px;
            margin-top: 10px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            .storybook-button {
                width: 100% !important;
                margin-top: 20px !important;
            }
        }
        &--proceed {
            width: 300px;
            margin-top: 10px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            text-align: center;

            b {
                text-align: center;
                font-size: 16px;
                color: map-get($Green, level_5);
                display: block;
            }
            img {
                width: 40px;
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
            .loading-gif {
                width: 100px;
            }
        }
    }
    &__success {
        height: calc(100% - 45px);
        width: 400px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        &--data {
            height: calc(100% - 45px);
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        &--gif {
            width: 70%;
            height: 70%;
            display: block;
            margin-left: auto;
            margin-right: auto;
            img {
                width: 100%;
            }
        }
        b {
            margin-top: 20px;
            font-size: 20px;
            font-family: $eduate-number-font;
        }
        span {
            display: block;
            font-size: 16px;
            font-family: $eduate-font-family;
        }
    }
}

.std-fee-details__block--data::-webkit-scrollbar {
    @include scrollbar();
}

.std-fee-details__block--data::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}

.std-fee-details__block--data::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}
