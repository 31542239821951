.profile-pic {
  height: 100%;
  &__title {
    img {
      @include closeIconInModal();
    }
  }
  &__preview {
    width: 250px;
    height: 250px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    display: block;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__btns {
    display: flex;
    column-gap: 5px;
  }
  &__browse {
    @include browseFiles();
    margin: 10px 10px 0px 0px;
    height: 35px !important;
    width: fit-content;
    &--input {
      display: none;
    }
  }
}
