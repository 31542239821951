.enable-user-login {
    height: 100%;

    &__options {
        column-gap: 10px;

        .MuiFormControlLabel-label {
            color: var(--text-color) !important;
            font-weight: bold !important;
            font-size: 18px !important;
            margin: 2px 10px 0px 10px !important;
            font-family: $eduate-font-family;
        }

        .Mui-checked {
            color: var(--level-5) !important;
        }

        &--button {
            @include addButton();
        }
    }


    &__textfield {
        @include textfield();

        &--email {
            @include textfieldInForms();

        }

    }



    &__tableblock {
        margin-top: 10px;
        height: calc(100% - 115px);
    }


}