.student-software-config {
    overflow-y: auto;
    height: calc(100% - 243px);
    width: 100%;

    &--title {
        img {
            @include closeIconInModal()
        }
    }

    &__modal {
        overflow-y: auto;
        height: calc(100% - 105px);
        width: 100%;
    }

    &__data {
        column-count: 4;
        margin: 0px 20px 0px 40px;
        column-gap: 20px;
        column-rule: 4px double map-get($colors, cloud-white);

        .Mui-checked {
            color: var(--level-5) !important;
        }

        .MuiCheckbox-root {
            padding: 0px !important;
        }
    }

    &__buttons {
        margin-left: 20px;
    }
}

.student-software-config::-webkit-scrollbar {
    @include scrollbar();
}

.student-software-config::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}

.student-software-config::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}