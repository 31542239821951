.reset-password {
  height: 100%;

  &__details {
    height: 120px;
    column-gap: 20px;

    &--image {
      height: 100%;
      @include studentProfile();
    }

    &--imagefield {
      display: flex;

      .MuiAutocomplete-root {
        margin-top: 10px !important;
      }

      .data-fetch-icon {
        margin-top: 8px;
      }
    }

    &--textfield {
      @include textfield();
      margin: 10px 0px 0px 0px !important;
      width: 100%;

      &--date {
        @extend .reset-password__details--textfield;
        width: fit-content !important;
      }
    }

    &--autocomplete {
      display: flex;
      column-gap: 5px;
      .MuiAutocomplete-root {
        width: 100%;
      }
    }
  }

  &__form {
    border-top: 1.8px dashed var(--border-color);
    height: calc(100% - 200px);
    margin-top: 10px;

    &--pwd {
      @include textfield();
      margin: 10px 0px 0px 0px !important;
      width: 100%;

      input {
        font-weight: 400 !important;
      }

      .MuiOutlinedInput-input {
        padding: 6.5px 14px !important;
      }

      .MuiSvgIcon-root {
        font-size: 20px !important;
      }
    }
  }
}
