.assigned-list {
    height: 100%;

    &__filters {
        column-gap: 10px;

        &--button {
            @include addButton();
        }
    }

    &__tableblock {
        margin-top: 10px;
        height: calc(100% - 130px);
    }
}