.fee-fetch-details {
  height: 100%;
  padding: 20px;
  &__block {
    height: calc(100% - 20px);
    background-color: map-get($blue, level_1);
    position: relative;
    &--inst-details {
      padding: 10px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
      text-align: center;
      img {
        width: 60px;
        height: 60px;
      }
      span {
        display: block;
        font-family: $eduate-font-family;
        font-size: $eduate-default-font-size;
        color: map-get($cool-grey, level_6);
        margin-top: 5px;
      }
      b {
        font-family: $eduate-font-family;
        font-size: 16px;
      }
    }
    &--frame {
      display: block;
      margin: auto;
      width: 400px;
      height: fit-content;
      border-radius: 6px;
      background-color: map-get($blue, level_2);
      padding: 40px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      .storybook-button{
        width: 100% !important;
        
      }
      &--tabs {
        margin-top: 10px;
        @include muiTabs();
        .MuiTabs-indicator {
          display: none !important;
        }
      }
    }
    &--footer {
      display: flex;
      justify-content: center;
      &--data{
        position: absolute;
        bottom: 5px;
        display: flex;
        align-items: center;
        column-gap: 5px;

      }
      &--text {
        color: map-get($cool-grey, level_6);
        font-family: $eduate-font-family;
        font-size: $eduate-default-font-size;
      }
      b {
        color: #01366e !important;
        font-size: 16px !important;
        font-weight: 700 !important;
      }
    }
    &--fetch-details{
      width: 100%;
      height: 35px;
      display: flex;
      justify-content: center;
      column-gap: 5px;
      background-color: var(--level-7);
      border-radius: 6px;
      color: map-get($colors, white) !important;
      align-items:center;
      font-family: $eduate-font-family;
      font-size: $eduate-default-font-size;
      font-weight: 600;

      img{
        width: 25px;
      }
      &:hover {
        background-color:var(--level-5);
      }
    
      &:focus {
        background-color:var(--level-8);
      }
    
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
 
  }
}
