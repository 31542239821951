.edu-global-configuration {
  height: 100%;

  &__modal {
    height: 100%;

    .edu-global-configuration__block {
      height: calc(100% - 80px);
    }
  }

  &__title {
    img {
      @include closeIconInModal();
    }
  }

  .MuiFormControlLabel-root {
    margin-right: 0px !important;
    font-family: $eduate-font-family;
  }

  .MuiTypography-root {
    color: map-get($colors, white);
    margin-top: 3px;
    font-family: $eduate-font-family !important;
  }

  .MuiSwitch-root {
    margin-top: 8px;
  }

  .Label {
    margin-top: 5px;
  }

  .select {
    margin-top: 5px !important;
  }

  &__data {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 50px;
    -moz-column-gap: 50px;
    column-gap: 50px;
    column-rule: 4px dotted var(--border-color);

    &--parameters {
      display: grid;
      grid-template-columns: 1fr 1fr;
      border-bottom: 1px dashed var(--border-color);
      padding-bottom: 5px;
    }
  }

  &__block {
    height: calc(100% - 50px);
    overflow-y: auto;
    margin-top: 10px;

    .MuiAccordion-region {
      height: 100%;
    }

    .MuiCollapse-wrapperInner {
      height: 100% !important;
    }

    &--details {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      column-fill: auto;
      -webkit-column-gap: 50px;
      -moz-column-gap: 50px;
      column-gap: 50px;
      column-rule: 4px dotted var(--border-color);
      height: 100%;

      .MuiAccordionSummary-content {
        .MuiTypography-root {
          color: map-get($colors, white) !important;
          margin-top: 3px;
        }
      }

      .MuiFormControlLabel-root .MuiFormControlLabel-label {
        font-family: $eduate-font-family;
        font-style: normal;
        font-weight: 700 !important;
        font-size: 14px;
        color: var(--text-color);
      }

      .MuiButtonBase-root-MuiCheckbox-root {
        padding: 0px;
      }

      .MuiFormLabel-root {
        margin-left: 8px;
      }

      .MuiFormControlLabel-root {
        justify-content: space-between !important;
        margin-left: 5px !important;
        margin-right: 0px !important;
        margin-top: 5px;
      }
    }
  }
}

.edu-global-configuration__block::-webkit-scrollbar {
  @include scrollbar();
}

.edu-global-configuration__block::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.edu-global-configuration__block::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
