
.header {
    background-color: var(--bg-color);
    height: 50px;
    padding: 10px;

    &--inst-name {
        font-family: $eduate-font-family;
        font-size: 16px;
        color: var(--text-color);
        font-weight: 700;
    }

    &--myeduate {
        color: $RED_COLOR!important;
        font-size: 16px !important;
        font-weight: 700 !important;
        width: 180px;

        img {
            margin-right: 10px;
        }
    }

    img {
        width: 30px;
        filter: invert(0%);
        cursor: pointer;
    }

    .logo-1 {
        width: 30px;
        height: 30px;
        filter: none !important;
    }

    &__icon {
        display: flex;
        justify-content: flex-end;
        column-gap: 10px;

        &--li {
            width: 250px;

            .MuiFormControl-root {
                margin-top: 0px !important;
            }
        }
    }

    &__search-icon {
        width: 18px !important;
    }

    &__logo {
        display: inline-flex;

        p {
            padding-left: 5px;
            font-size: 16px;
            font-weight: 500;
            font-family: $eduate-font-family;
        }

        img {
            margin-top: -10px;
        }
    }

    &__student-details {
        &--navigation {
            background: var(--level-2);
            padding: 10px 2px;
            border-radius: 5px 0px;
            display: flex;
            justify-content: space-between;

            &--info {
                filter: invert(100%);
            }

            img {
                width: 30px;
            }
        }

        &--data {
            padding: 10px;

            &--label-grid {
                display: grid;
                grid-template-columns: 1fr 0.2fr 1fr;

                Label {
                    margin-top: 0px !important;
                }
            }

            &--student-profile {
                height: 120px;
                width: 120px;
            }
        }
    }

    &__inst-details {
        display: flex;
        column-gap: 10px;
        align-items: center;
    }

    .MuiAutocomplete-root {
        width: 100%;

        img {
            width: 20px !important;
        }
    }
}