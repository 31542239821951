.send-message {
    height: 100%;

    &__title {
        img {
            @include closeIconInModal()
        }
    }

    &__form {
        height: calc(100% - 75px);

        &--label-grid1 {
            display: grid;
            grid-template-columns: .3fr 1fr;
            height: 150px;
        }

        &--label-grid2 {
            display: grid;
            grid-template-columns: .3fr 1fr;
            height: calc(100% - 160px);
            margin-top: 10px;
        }

        &--attachments {
            border: 1px solid var(--border-color);
            margin-top: 10px;
            border-radius: 6px;
            height: calc(100% - 55px);
            border: 1px solid var(--border-color);
            position: relative;
            overflow: hidden;



            ul {
                height: calc(100% - 25px);
                padding: 5px;
                background-image: url("../../../../../images/Attachment.svg");
                background-size: 40px;
                background-repeat: no-repeat;
                background-position: center;
                list-style: none;
            }

            li {
                font-family: $eduate-font-family;
                font-size: 14px;
            }

            &--footer {
                height: 25px;
                background-color: map-get($cool-grey, level_6);
                color: map-get($colors, white);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 11px;
                font-family: $eduate-font-family;
                font-weight: 500;
            }
        }
    }
}

.ql-toolbar {
    position: sticky;
    top: 0;
    background-color: #fff;
    border-style: none !important;
    z-index: 1;
    padding: 0px !important;
    border-top: none !important;
    border-bottom: 1px solid var(--border-color) !important;
    border-left: none !important;
    border-right: none !important;
}

.ql-container {
    border-style: none !important;
}

.ql-editor {
    padding: 5px !important;
    height: 105px !important;

    p {
        font-family: $eduate-font-family;
        font-size: 14px;
    }

    &::placeholder {
        font-family: $eduate-font-family;
        font-size: 14px;
    }
}

.quill {
    border: 1px solid var(--border-color) !important;
    margin-top: 10px !important;
}

.send-message__form--attachments input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}