.inst-config__masters {
    margin-top: 10px;
    height: calc(100% - 45px);

    &--title {
        img {
            @include closeIconInModal();
        }
    }

    &--datablock {
        height: calc(100% - 100px);
        margin-top: 5px;
        @include bookTypeFrameStyle();
    }

    &--tableblock {
        height: calc(100% - 30px);

    }

    &--table {
        @include table();

        &--slno {
            width: calc(100% - 92%);
            font-family: $eduate-number-font;
        }

        tr:hover {
            .inst-config__masters--table--name {
                @include tdContainsOnclick();
            }
        }

        &--actions {
            width: calc(100% - 88%);
        }
    }
}

.inst-breadcrumbs {
    height: 20px;


    a {
        font-size: 14px;
        margin-bottom: 0px;
        color: var(--level-6);
        font-weight: 500;
        font-family: $eduate-font-family;
    }
}

#td-center {
    text-align: center;
}