@import "../../scss/common/fonts";
@import "../../scss/common/colors";
@import "../../scss/common/mixin";

.storybook-textarea {
  padding-left: 1rem;
  border-radius: 6px;
  border: 1px solid var(--border-color);
  background-color: var(--bg-color);
  width: 100%;
  height: 65px !important;
  margin-top: 5px;
  padding: 5px;
  font-size: $eduate-default-font-size;
  font-family: $eduate-font-family;
  color: var(--text-color);
}

.storybook-textarea:focus {
  border: 1px solid var(--level-3);
}

.storybook-textarea:disabled {
  background-color: var(--disabled-color);
  color: var(--text-color);
}

.storybook-textarea::-webkit-scrollbar {
  @include scrollbar();
}

.storybook-textarea::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.storybook-textarea::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
