.message-for-individual {
    height: 100%;

    &__title {
        img {
            @include closeIconInModal();
        }
    }

    &__message-list {
        height: calc(100% - 215px);
        border: 1px solid var(--border-color);
        margin-top: 10px;
    }
}